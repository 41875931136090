import React, { useState, useRef, useCallback, useEffect } from "react";
import "../../assets/css/pricing.css";
// import Dropzone from "react-dropzone";
// import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { axiosRequest } from "../../../api";
import { baseURL } from "../../../constant";
import moment from "moment";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

//import StripeCheckout from 'react-stripe-checkout';


const Step5 = (props) => {
  const stripePromise = loadStripe('pk_test_51Nq6CmSCmmwBLcP64DjmaK2NpkiqZFZV2YUMuoMb076I6qJny1LHLOcd1YUhNFUZNETwIlLfBP0Vka8JEQO8rFEG00sdaMhFZT');

  const cookies = new Cookies();
  const [setting, setSetting] = useState("");
  const [loading, setLoading] = useState(false);
  const [deliveryCharge, setdeliveryCharge] = useState("");
  const [taxAmount, settaxAmount] = useState(props.taxAmount);
  const [currencyText, setcurrencyText] = useState(props.currencyText);
  //const [currencytext, setCurrencytext] = useState("USD");


  
  const [clientSecret, setClientSecret] = useState("");
  const [clientSecretID, setclientSecretID] = useState("");
  const items = [{ amount: '11' }];
  const currency = cookies.get("currency");

  console.log(currency)
  
  
  console.log("ssssssss")
    console.log(currencyText) 
    console.log("testttt")

  useEffect(() => {
    
   if(currency == "1"){
       settaxAmount('10');
      }else{   
        settaxAmount('0.00');
      }
    const loadSetting = async () => {
      
      // Till the data is fetch using API
      // the Loading page will show.
      setLoading(true);
      // Await make wait until that
      // promise settles and return its result
      const response = await axiosRequest.get(`${baseURL}/getsetSetting?currency=1`);
      
      // After fetching data stored it in posts state.
      setSetting(response.data);
      setdeliveryCharge(response.data.regular_delivery_amount);
      //
      // Closed the loading page
      setLoading(false);
    };
    loadSetting();

    const items = [{ amount: cookies.get("price") }];

    const loadSecretKey = async () => {
      // Till the data is fetch using API
      // the Loading page will show.
      setLoading(true);
      // Await make wait until that
      // promise settles and return its result

    //  const fetchClientSecret = async () => {
        try {
            const response = await axiosRequest.get(`${baseURL}/getloadSecretKey`, {
                items,
            });
            console.log(response.data.clientSecret)
            cookies.set("stripeToken", response.data.clientSecret, { path: "/" });
            cookies.set("clientSecretID", response.data.clientSecretID, { path: "/" });
            setClientSecret(response.data.clientSecret);
            setclientSecretID(response.data.clientSecretID);
        } catch (error) {
            console.log(error);
        }
    //}

/*
      const response = await axiosRequest.get(`${baseURL}/getloadSecretKey`);
      console.log(response.data.clientSecret);
      // After fetching data stored it in posts state.
      setSecretKey(response.data.clientSecret);
      */
      // Closed the loading page
      setLoading(false);
    };
    loadSecretKey();


  }, [1]);

 //console.log(props.currency)

  if (props.currentStep !== 5) {
    return null;
  }
  // Cookies  variable
  const jobType = cookies.get("jobType")
    ? cookies.get("jobType")
    : "Basic Service";
  
 
  const deliverydate = cookies.get("deliverydate");
  const wordlength = cookies.get("wordlength");
  const serviceType = cookies.get("serviceType");
  const price = cookies.get("price");
  const formattedDeliveryDate =
    moment(deliverydate).format("DD MMM YYYY HH:mm");
  const calTotalPrice =
    parseInt(price) +
    parseInt(setting.regular_delivery_amount) +
    parseInt(props.taxAmount);
  cookies.set("fullAmount", calTotalPrice, { path: "/" });
  return (
    <>
      <section className="pt-2 pb-2">
        <div className="container">
          <div className="row">
            <div class="col-md-6 col-sm-12 col-12">
              <h4 className="package_selection">
                Package Selected{" "}
                <span className="d-block mt-2 mb-4">{jobType}</span>
              </h4>
              <div className="deadline_time">
                <h5>
                  Deadline : <span>{formattedDeliveryDate}</span>
                </h5>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 col-12">
              <table className="table table-bordered payment-table">
                <tbody>
                  <tr>
                    <td>Service Type</td>
                    <td className="text-right">
                      {serviceType === undefined ? "Editing" : ""}
                      {serviceType == 1 ? "Editing" : ""}
                      {serviceType == 2 ? "Proofreading" : ""}
                      {serviceType == 3 ? "Formating" : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      {jobType === undefined ? "Basic Service" : jobType}
                    </td>
                    <td className="text-right"> {props.currencyText} {price}</td>
                  </tr>
                  <tr>
                    <td>Tax</td>
                    <td className="text-right"> {props.currencyText} {props.taxAmount}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Total Amount</b>
                    </td>
                    <td className="text-right">
                      <b>
                        {props.currencyText}
                        {parseInt(price) +
                          parseInt(deliveryCharge) +
                          parseInt(props.taxAmount)}{" "}
                      </b>
                      {/* <b>${parseInt(price) + parseInt(11)}</b> */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <div className="form-group">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header p-0 d-none" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-light btn-block text-left collapsed p-3 rounded-0 border-bottom-custom"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <span>Paypal</span>
                            <img
                              src="https://i.imgur.com/7kQEsHU.png"
                              alt=" "
                              width="30"
                            />
                          </div>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Paypal email"
                        />
                      </div>
                    </div>
                  </div>
                    
                  <div className="card">
                
                  <Elements stripe={stripePromise} options={{clientSecret}}>
                    <CheckoutForm props={props}/>
                </Elements>
                    <div className="card-header p-0 d-none">
                      <div className="mb-0">
                        <a
                          herf="#"
                          className="btn btn-light btn-block text-left p-3 rounded-0"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <span>Credit card</span>
                            <div className="icons">
                              <img
                                src="https://i.imgur.com/2ISgYja.png"
                                alt=" "
                                width="30"
                              />
                              <img
                                src="https://i.imgur.com/W1vtnOV.png"
                                alt=" "
                                width="30"
                              />
                              <img
                                src="https://i.imgur.com/35tC99g.png"
                                alt=" "
                                width="30"
                              />
                              <img
                                src="https://i.imgur.com/2ISgYja.png"
                                alt=" "
                                width="30"
                              />
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                   
                    
                    <div
                      id="collapseOne"
                      className="collapse show d-none"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body payment-card-body">
                        <span className="font-weight-normal card-text">
                          Card Number
                        </span>
                        <div className="input">
                          <i className="fa fa-credit-card"></i>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0000 0000 0000 0000"
                          />
                        </div>

                        <div className="row mt-3 mb-3">
                          <div className="col-md-6">
                            <span className="font-weight-normal card-text">
                              Expiry Date
                            </span>
                            <div className="input">
                              <i className="fa fa-calendar"></i>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="MM/YY"
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <span className="font-weight-normal card-text">
                              CVC/CVV
                            </span>
                            <div className="input">
                              <i className="fa fa-lock"></i>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="000"
                              />
                            </div>
                          </div>
                        </div>

                        <span className="text-muted certificate-text">
                          <i className="fa fa-lock"></i> Your transaction is
                          secured with ssl certificate
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step5;
