import React from "react";

function WeWork(){
    return(
       <>
          <section className="inner-page section-themebg">
                <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>How We Work</h3>
                    </div>
                </div>
                </div>
            </section>

            
        <section class="section-padding">
            <div class="container">
            <div class="row">
            
            <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                    <div class="icon">
                        <img src={process.env.PUBLIC_URL + '/assets/images/upload.png'} alt=""/>
                    </div>
                    <div class="feature-content">
                        <h5><span>01</span>Upload</h5>
                        <p>Upload your documents and provide your word count, delivery deadline, and any additional requirements.</p>
                    </div>
                </div>
            </div>
             
            <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                    <div class="icon">
                         <img src={process.env.PUBLIC_URL + '/assets/images/payment.png'} alt=""/>
                    </div>
                    <div class="feature-content">
                        <h5><span>02</span>Payment</h5>
                        <p>Our payment methods, Stripe and Paypal, guarantee your secure payment.</p>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                    <div class="icon">
                        <img src={process.env.PUBLIC_URL + '/assets/images/editor.png'} alt=""/>
                    </div>
                    <div class="feature-content">
                        <h5><span>03</span>Editor</h5>
                        <p>Your job will be assigned to the most suitable editor.</p>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                    <div class="icon">
                         <img src={process.env.PUBLIC_URL + '/assets/images/collaborate.png'} alt=""/>
                    </div>
                    <div class="feature-content">
                        <h5><span>04</span>Collaborate</h5>
                        <p>You can message your editor or chief editor at any time. We encourage collaboration, as it leads to improved results.</p>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                    <div class="icon">
                          <img src={process.env.PUBLIC_URL + '/assets/images/delivery.png'} alt=""/> 
                    </div>
                    <div class="feature-content">
                        <h5><span>05</span>Delivery</h5>
                        <p>You can message your editor or chief editor at any time. We encourage collaboration, as it leads to improved results.</p>
                    </div>
                </div>
            </div>
          
            <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                    <div class="icon">
                        <img src={process.env.PUBLIC_URL + '/assets/images/feedback.png'} alt=""/> 
                    </div>
                    <div class="feature-content">
                        <h5><span>06</span>Feedback</h5>
                        <p>You can message your editor or chief editor at any time. We encourage collaboration, as it leads to improved results.</p>
                    </div>
                </div>
            </div>
             
        </div>
         </div>
         </section>
            {/* <section class="how-it-works section-padding bg-light">
            <div class="container">
                <div class="row">
                <div class="row pb-5">
        
                    <div class="col-md-6 mt-2 mt-md-0 p-2 pl-4 box mb-3">
                    <div class="d-flex p-3 align-items-center justify-content-between">
                        <div class="p-1">
                        <h3 class="h4 mt-3">Upload</h3>
                        <p class="mt-3"> Upload your documents and provide your word count, delivery deadline, and any 
additional requirements.</p>
                        </div>
                    </div>
                    </div>
        
                    <div class="col-md-6 mt-2 mt-md-0 p-2 pl-4 box mb-3">
                    <div class="d-flex p-3 align-items-center justify-content-between">
                        <div class="p-1">
                        <h3 class="h4 mt-3">Payment</h3>
                        <p class="mt-3">Our payment methods, Stripe and Paypal, guarantee your secure payment.</p>
                        </div>
                    </div>
                    </div>
        
                    <div class="col-md-6 mt-2 mt-md-0 p-2 pl-4 box mb-3">
                    <div class="d-flex p-3 align-items-center justify-content-between">
                        <div class="p-1">
                        <h3 class="h4 mt-3">Editor</h3>
        
                        <p class="mt-3">Your job will be assigned to the most suitable editor.</p>
                        </div>
                    </div>
                    </div>
        
                    <div class="col-md-6 mt-2 mt-md-0 p-2 pl-4 box mb-3">
                    <div class="d-flex p-3 align-items-center justify-content-between">
                        <div class="p-1">
                        <h3 class="h4 mt-3">Collaborate</h3>
                        <p class="mt-3">You can message your editor or chief editor at any time. We encourage collaboration, as it leads 
to improved results.</p>
                        </div>
                    </div>
                    </div>
        
                    <div class="col-md-6 mt-2 mt-md-0 p-2 pl-4 box mb-3">
                    <div class="d-flex p-3 align-items-center justify-content-between">
                        <div class="p-1">
                        <h3 class="h4 mt-3 ">Delivery</h3>
                        <p class="mt-3">You can message your editor or chief editor at any time. We encourage collaboration, as it leads 
to improved results.</p>
                        </div>
                    </div>
                    </div>
        
                    <div class="col-md-6 mt-2 mt-md-0 p-2 pl-4 box mb-3">
                    <div class="d-flex p-3 align-items-center justify-content-between">
                        <div class="p-1">
                        <h3 class="h4 mt-3 ">Feedback</h3>
                        <p class="mt-3">You can message your editor or chief editor at any time. We encourage collaboration, as it leads 
to improved results.</p>
                        </div>
                    </div>
                    </div>
        
                </div>
                </div>
            </div>
            </section>   */}

            {/* <section className="section-padding">
                  <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-12 col-12">
                         
                          <img src={process.env.PUBLIC_URL + '/assets/images/work.png'} className="img-fluid w-100" alt=""/>
                      </div>

                      <div className="col-md-8 col-sm-12 col-12">
                           <div className="work-box">
                                <div className="img-box pt-1"> <img src={process.env.PUBLIC_URL + '/assets/images/upload.png'} alt=""/></div>
                                <div className="content">
                                     <h3><span>01</span> Upload</h3>
                                     
                                </div>
                            </div>  

                            <div className="work-box">
                                <div className="img-box"> <img src={process.env.PUBLIC_URL + '/assets/images/payment.png'} alt=""/></div>
                                <div className="content">
                                     <h3><span>02</span> Payment</h3>
                                     <p></p>
                                </div>
                            </div> 


                            <div className="work-box">
                                <div className="img-box"> <img src={process.env.PUBLIC_URL + '/assets/images/editor.png'} alt=""/></div>
                                <div className="content">
                                     <h3><span>03</span> Editor</h3>
                                     <p></p>
                                </div>
                            </div> 

                            <div className="work-box">
                                <div className="img-box pt-2"> <img src={process.env.PUBLIC_URL + '/assets/images/collaborate.png'} alt=""/></div>
                                <div className="content">
                                     <h3><span>04</span> </h3>
                                     <p></p>
                                </div>
                            </div> 

                            <div className="work-box">
                                <div className="img-box pt-1"> <img src={process.env.PUBLIC_URL + '/assets/images/delivery.png'} alt=""/></div>
                                <div className="content">
                                     <h3><span>05</span> </h3>
                                     <p></p>
                                </div>
                            </div> 

                            <div className="work-box">
                                <div className="img-box pt-1"> <img src={process.env.PUBLIC_URL + '/assets/images/feedback.png'} alt=""/></div>
                                <div className="content">
                                     <h3><span>06</span> Feedback</h3>
                                     <p></p>
                                </div>
                            </div> 


                      </div>

                    </div>
                  </div>
         </section> */}

       </>

    );
}

export default WeWork;