import React, { useState, useEffect } from "react";
import { axiosRequest } from "../../../api";
import { baseURL } from "../../../constant";
import ReactHtmlParser from "react-html-parser";
import Cookies from "universal-cookie";

const Step4 = (props) => {
  const cookies = new Cookies();
  const [selected, setSelected] = useState("");
  const [planList, setplanList] = useState("");
  const [loading, setLoading] = useState(false);
  const [setting, setSetting] = useState(false);

  // get service plan
  useEffect(() => {
    const loadPost = async () => {
      // Till the data is fetch using API
      // the Loading page will show.
      setLoading(true);
      // Await make wait until that
      // promise settles and return its result
      const response = await axiosRequest.get(`${baseURL}/getPlanList`);
      setplanList(response.data);

      // const setting = cookies.get("setting");
      // const price = cookies.get("price");
      // let calTotalPrice =
      //   parseInt(price) +
      //   parseInt(setting.regular_delivery_amount) +
      //   parseInt(setting.tax_amount);
      // cookies.set("fullAmount", calTotalPrice, { path: "/" });
      setSetting(setting);
      // Closed the loading page
      setLoading(false);
    };

    // Call the function
    loadPost();
    setSelected("1");
  }, []);

  // const changeHandler = (e) => {
  //   setSelected(e.target.value);
  //   if (e.target.value == 1) {
  //     cookies.set("jobType", planList[0]["title"], { path: "/" });
  //     cookies.set("price", planList[0]["price"], { path: "/" });
  //   } else {
  //     cookies.set("jobType", planList[1]["title"], { path: "/" });
  //     cookies.set("price", planList[1]["price"], { path: "/" });
  //   }
  // };

  const changeHandler = (e) => {
    setSelected(e.target.value);

    if (e.target.value === "1") {
      props.selectedJobType("Basic Service");
      // cookies.set("jobType", "Plus Service", { path: "/" });
    } else if (e.target.value === "2") {
      props.selectedJobType("Plus Service");
      // cookies.set("jobType", "Basic Service", { path: "/" });
    } else {
      props.selectedJobType("Basic Service");
    }

    const wordCount = cookies.get("wordlength");
    let price;
    let superPrice;
    if (wordCount >= 0 && wordCount <= 250) {
      price = 12.5;
      superPrice = 20;
    } else if (wordCount > 250 && wordCount <= 500) {
      price = 25;
      superPrice = 35;
    } else if (wordCount > 500 && wordCount <= 1000) {
      price = 35;
      superPrice = 45;
    } else if (wordCount > 1000 && wordCount <= 1500) {
      price = 40;
      superPrice = 60;
    } else if (wordCount > 1500 && wordCount <= 2500) {
      price = wordCount * 0.008 * 3.25;
      superPrice = wordCount * 0.011 * 3.35;
    } else if (wordCount > 2501 && wordCount <= 25000) {
      price = wordCount * 0.007 * 3.2;
      superPrice = wordCount * 0.011 * 3.3;
    } else if (wordCount > 25001 && wordCount <= 50000) {
      price = wordCount * 0.007 * 3;
      superPrice = wordCount * 0.011 * 3.1;
    } else if (wordCount > 50000) {
      price = wordCount * 0.007 * 2.9;
      superPrice = wordCount * 0.011 * 3;
    } else {
      price = 0;
      superPrice = 0;
    }
    price = price.toFixed(2);
    superPrice = superPrice.toFixed(2);
    if (e.target.value == 1) {
      cookies.set("price", price, { path: "/" });
      // let calTotalPrice =
      //   parseInt(price) +
      //   parseInt(setting.regular_delivery_amount) +
      //   parseInt(setting.tax_amount);
      // cookies.set("fullAmount", calTotalPrice, { path: "/" });
    } else if (e.target.value == 2) {
      cookies.set("price", superPrice, { path: "/" });
      // let calTotalPrice =
      //   parseInt(superPrice) +
      //   parseInt(setting.regular_delivery_amount) +
      //   parseInt(setting.tax_amount);
      // cookies.set("fullAmount", calTotalPrice, { path: "/" });
    }
  };
  // Cookies  variable
  const deliverydate = cookies.get("deliverydate");
  const wordlength = cookies.get("wordlength");
  const serviceType = cookies.get("serviceType");

  const [showDiv, setShowDiv] = useState(true);
  const [showParentDiv, setShowParentDiv] = useState(true);

  const handleButtonClick = () => {
    setShowDiv(true);
    setShowParentDiv(false);
  };

  if (props.currentStep !== 4) {
    return null;
  }

  return (
    <>
      <section className=" ">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="table-responsive-lg">
                <table className="table table-striped pricing-table">
                  <thead>
                    <tr>
                      <th className="border-0 pb-3 h3 plan-content-title">
                        Key Features
                      </th>
                      <th className="text-center border-0 pb-3">
                        <h6 className="lead font-weight-bold pricing-plan-title">
                          {planList[0]["title"]}
                        </h6>
                        {/* <p className="font-weight-bold pricing-plan-amount"><span className="h1">{planList[0]['price']}</span></p> */}
                        <p className="small pricing-plan-allotment">
                          Most popular, best value
                        </p>
                        {/* <button className="btn btn-block btn-outline-primary btn-rounded">Go <span className="d-none d-md-inline">Starter</span></button> */}
                      </th>
                      <th className="text-center border-0 pb-3">
                        <h6 className="lead font-weight-bold pricing-plan-title">
                          {planList[1]["title"]}
                        </h6>
                        {/* <p className="font-weight-bold pricing-plan-amount"><span className="h1">{planList[1]['price']}</span></p> */}
                        <p className="small pricing-plan-allotment">
                          The best, our top-of-the-line
                        </p>
                        {/* <button className="btn btn-block btn-outline-primary btn-rounded">Go <span className="d-none d-md-inline">Professional</span></button> */}
                      </th>

                      <th className="text-center border-0 pb-3">
                        <h6 className="lead font-weight-bold pricing-plan-title">
                          Customised service
                        </h6>
                        <p className="font-weight-bold pricing-plan-amount">
                          <span className="h1"></span>
                        </p>
                        <p className="small pricing-plan-allotment">
                          The best, our top-of-the-line
                        </p>
                        {/* <button className="btn btn-block btn-outline-primary btn-rounded">Go <span className="d-none d-md-inline">Professional</span></button> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row" className="border-0">
                        Proofread for errors
                      </td>
                      <td className="text-center">
                        <span className="icon icon-rounded icon-success">
                          <i className="fa-solid fa-check"></i>
                        </span>
                      </td>
                      <td className="text-center">
                        <span className="icon icon-rounded icon-success">
                          <i className="fa-solid fa-check"></i>
                        </span>
                      </td>
                      <td className="text-center">
                        {/* <div className="checkbox custom-service">
                                        <label className="checkbox-wrapper">
                                            <input type="checkbox" className="checkbox-input" />
                                            <span className="checkbox-tile">
                                                <span className="checkbox-label">Yes</span>
                                            </span>
                                        </label>
                                    </div>   */}
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">Perform limited style guide edits</td>
                      <td className="text-center">
                        <span className="icon icon-rounded icon-success">
                          <i className="fa-solid fa-check"></i>
                        </span>
                      </td>
                      <td className="text-center">
                        <span className="icon icon-rounded icon-success">
                          <i className="fa-solid fa-check"></i>
                        </span>
                      </td>
                      <td className="text-center">
                        {/* <div className="checkbox custom-service">
                                            <label className="checkbox-wrapper">
                                                <input type="checkbox" className="checkbox-input" />
                                                <span className="checkbox-tile">
                                                    <span className="checkbox-label">Yes</span>
                                                </span>
                                            </label>
                                        </div>    */}
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">Fine-tune references</td>
                      <td className="text-center">
                        <span className="icon icon-rounded icon-success">
                          <i className="fa-solid fa-check"></i>
                        </span>
                      </td>
                      <td className="text-center">
                        <span className="icon icon-rounded icon-success">
                          <i className="fa-solid fa-check"></i>
                        </span>
                      </td>
                      <td className="text-center">
                        {/* <div className="checkbox custom-service">
                                            <label className="checkbox-wrapper">
                                                <input type="checkbox" className="checkbox-input" />
                                                <span className="checkbox-tile">
                                                    <span className="checkbox-label">Yes</span>
                                                </span>
                                            </label>
                                        </div>    */}
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">Identify potential issues</td>
                      <td className="text-center">
                        <span className="icon icon-rounded icon-success">
                          <i className="fa-solid fa-check"></i>
                        </span>
                      </td>
                      <td className="text-center">
                        <span className="icon icon-rounded icon-success">
                          <i className="fa-solid fa-check"></i>
                        </span>
                      </td>
                      <td className="text-center">
                        {/* <div className="checkbox custom-service">
                                            <label className="checkbox-wrapper">
                                                <input type="checkbox" className="checkbox-input" />
                                                <span className="checkbox-tile">
                                                    <span className="checkbox-label">Yes</span>
                                                </span>
                                            </label>
                                        </div>    */}
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">Generate brief feedback</td>
                      <td className="text-center">
                        <span className="icon icon-rounded icon-success">
                          <i className="fa-solid fa-check"></i>
                        </span>
                      </td>
                      <td className="text-center">
                        <span className="icon icon-rounded icon-success">
                          <i className="fa-solid fa-check"></i>
                        </span>
                      </td>
                      <td className="text-center">
                        {/* <div className="checkbox custom-service">
                                            <label className="checkbox-wrapper">
                                                <input type="checkbox" className="checkbox-input" />
                                                <span className="checkbox-tile">
                                                    <span className="checkbox-label">Yes</span>
                                                </span>
                                            </label>
                                        </div>    */}
                      </td>
                    </tr>
                    {/*
                    {showParentDiv && (
                      <tr className="more_btn_box" colspan="3" id="parentDiv">
                        <td>
                          <button
                            type="button"
                            className="show_more"
                            onClick={handleButtonClick}
                          >
                            {showDiv ? "Hide Div" : "Show More"}
                          </button>
                        </td>
                      </tr>
                    )}*/}
                  </tbody>
                </table>
                {/* {showDiv && } */}
                {showDiv && (
                  <table className="table table-striped pricing-table">
                    <tbody className="more_list">
                      <tr>
                        <td scope="row" className="border-bottom">
                          Provide basic follow-up edits
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-danger">
                            <i className="fa-solid fa-xmark"></i>
                          </span>
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-success">
                            <i className="fa-solid fa-check"></i>
                          </span>
                        </td>
                        <td className="text-center">
                          {/* <div className="checkbox custom-service">
                                            <label className="checkbox-wrapper">
                                                <input type="checkbox" className="checkbox-input" />
                                                <span className="checkbox-tile">
                                                    <span className="checkbox-label">Yes</span>
                                                </span>
                                            </label>
                                        </div>    */}
                        </td>
                      </tr>

                      <tr>
                        <td scope="row" className="border-bottom">
                          Resolve potential issues
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-danger">
                            <i className="fa-solid fa-xmark"></i>
                          </span>
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-success">
                            <i className="fa-solid fa-check"></i>
                          </span>
                        </td>
                        <td className="text-center">
                          {/* <div className="checkbox custom-service">
                                            <label className="checkbox-wrapper">
                                                <input type="checkbox" className="checkbox-input" />
                                                <span className="checkbox-tile">
                                                    <span className="checkbox-label">Yes</span>
                                                </span>
                                            </label>
                                        </div>    */}
                        </td>
                      </tr>

                      <tr>
                        <td scope="row" className="border-bottom">
                          Ensure compliance to the style guide
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-danger">
                            <i className="fa-solid fa-xmark"></i>
                          </span>
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-success">
                            <i className="fa-solid fa-check"></i>
                          </span>
                        </td>
                        <td className="text-center">
                          {/* <div className="checkbox custom-service">
                                            <label className="checkbox-wrapper">
                                                <input type="checkbox" className="checkbox-input" />
                                                <span className="checkbox-tile">
                                                    <span className="checkbox-label">Yes</span>
                                                </span>
                                            </label>
                                        </div>    */}
                        </td>
                      </tr>

                      <tr>
                        <td scope="row" className="border-bottom">
                          Correct outright errors
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-danger">
                            <i className="fa-solid fa-xmark"></i>
                          </span>
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-success">
                            <i className="fa-solid fa-check"></i>
                          </span>
                        </td>
                        <td className="text-center">
                          {/* <div className="checkbox custom-service">
                                            <label className="checkbox-wrapper">
                                                <input type="checkbox" className="checkbox-input" />
                                                <span className="checkbox-tile">
                                                    <span className="checkbox-label">Yes</span>
                                                </span>
                                            </label>
                                        </div>    */}
                        </td>
                      </tr>

                      <tr>
                        <td scope="row" className="border-bottom">
                          Edit references
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-danger">
                            <i className="fa-solid fa-xmark"></i>
                          </span>
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-success">
                            <i className="fa-solid fa-check"></i>
                          </span>
                        </td>
                        <td className="text-center">
                          {/* <div className="checkbox custom-service">
                                            <label className="checkbox-wrapper">
                                                <input type="checkbox" className="checkbox-input" />
                                                <span className="checkbox-tile">
                                                    <span className="checkbox-label">Yes</span>
                                                </span>
                                            </label>
                                        </div>    */}
                        </td>
                      </tr>

                      <tr>
                        <td scope="row" className="border-bottom">
                          Perform advanced formatting tasks
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-danger">
                            <i className="fa-solid fa-xmark"></i>
                          </span>
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-success">
                            <i className="fa-solid fa-check"></i>
                          </span>
                        </td>
                        <td className="text-center">
                          {/* <div className="checkbox custom-service">
                                            <label className="checkbox-wrapper">
                                                <input type="checkbox" className="checkbox-input" />
                                                <span className="checkbox-tile">
                                                    <span className="checkbox-label">Yes</span>
                                                </span>
                                            </label>
                                        </div>    */}
                        </td>
                      </tr>
                      <tr>
                        <td scope="row" className="border-bottom">
                          Format references{" "}
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-danger">
                            <i className="fa-solid fa-xmark"></i>
                          </span>
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-success">
                            <i className="fa-solid fa-check"></i>
                          </span>
                        </td>
                        <td className="text-center">
                          {/* <div className="checkbox custom-service">
                                            <label className="checkbox-wrapper">
                                                <input type="checkbox" className="checkbox-input" />
                                                <span className="checkbox-tile">
                                                    <span className="checkbox-label">Yes</span>
                                                </span>
                                            </label>
                                        </div>    */}
                        </td>
                      </tr>

                      <tr>
                        <td scope="row" className="border-bottom">
                          Pinpoint revision opportunities
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-danger">
                            <i className="fa-solid fa-xmark"></i>
                          </span>
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-success">
                            <i className="fa-solid fa-check"></i>
                          </span>
                        </td>
                        <td className="text-center">
                          {/* <div className="checkbox custom-service">
                                            <label className="checkbox-wrapper">
                                                <input type="checkbox" className="checkbox-input" />
                                                <span className="checkbox-tile">
                                                    <span className="checkbox-label">Yes</span>
                                                </span>
                                            </label>
                                        </div>    */}
                        </td>
                      </tr>

                      <tr>
                        <td scope="row" className="border-bottom">
                          Generate in-depth constructive criticism
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-danger">
                            <i className="fa-solid fa-xmark"></i>
                          </span>
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-success">
                            <i className="fa-solid fa-check"></i>
                          </span>
                        </td>
                        <td className="text-center">
                          {/* <div className="checkbox custom-service">
                                            <label className="checkbox-wrapper">
                                                <input type="checkbox" className="checkbox-input" />
                                                <span className="checkbox-tile">
                                                    <span className="checkbox-label">Yes</span>
                                                </span>
                                            </label>
                                        </div>    */}
                        </td>
                      </tr>

                      <tr>
                        <td scope="row" className="border-bottom">
                          Provide extensive follow-up edits
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-danger">
                            <i className="fa-solid fa-xmark"></i>
                          </span>
                        </td>
                        <td className="text-center border-bottom">
                          <span className="icon icon-rounded icon-success">
                            <i className="fa-solid fa-check"></i>
                          </span>
                        </td>
                        <td className="text-center">
                          {/* <div className="checkbox custom-service">
                                            <label className="checkbox-wrapper">
                                                <input type="checkbox" className="checkbox-input" />
                                                <span className="checkbox-tile">
                                                    <span className="checkbox-label">Yes</span>
                                                </span>
                                            </label>
                                        </div>    */}
                        </td>
                      </tr>
                    </tbody>

                    <tfoot>
                      <tr>
                        <td>Select Service</td>
                        <td>
                          <label className="custom-radio-button__container">
                            <input
                              type="radio"
                              name="planType"
                              value={planList[0]["id"]}
                              checked={selected === "1"}
                              onChange={changeHandler}
                            />
                            <span className="custom-radio-button designer">
                              Select Basic
                            </span>
                          </label>
                        </td>
                        <td>
                          <label className="custom-radio-button__container">
                            <input
                              type="radio"
                              name="planType"
                              value={planList[1]["id"]}
                              checked={selected === "2"}
                              onChange={changeHandler}
                            />
                            <span className="custom-radio-button">
                              Select Plus
                            </span>
                          </label>
                        </td>
                        <td>
                          <button className="btn btn-block btn-outline-light btn-rounded">
                            Contact us
                          </button>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                )}
              </div>

              <div className="row">
                <div className="col-12">
                  <div
                    className="addon-box"
                    aria-hidden={selected !== "basic" ? true : false}
                  >
                    <h3 className="text-center mb-4">
                      Package selected : <span>Basic Service</span>
                    </h3>
                    <h4>Addons for Basic Service</h4>
                    <div className="table-responsive-lg">
                      <table className="table table-striped pricing-table">
                        <tbody>
                          <tr>
                            <td scope="row" className="border-0">
                              Proofread for errors
                            </td>

                            <td className="text-center">
                              <div className="checkbox custom-service">
                                <label className="checkbox-wrapper">
                                  <input
                                    type="checkbox"
                                    className="checkbox-input"
                                  />
                                  <span className="checkbox-tile">
                                    <span className="checkbox-label">Yes</span>
                                  </span>
                                </label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td scope="row">
                              Perform limited style guide edits
                            </td>
                            <td className="text-center">
                              <div className="checkbox custom-service">
                                <label className="checkbox-wrapper">
                                  <input
                                    type="checkbox"
                                    className="checkbox-input"
                                  />
                                  <span className="checkbox-tile">
                                    <span className="checkbox-label">Yes</span>
                                  </span>
                                </label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td scope="row">Fine-tune references</td>

                            <td className="text-center">
                              <div className="checkbox custom-service">
                                <label className="checkbox-wrapper">
                                  <input
                                    type="checkbox"
                                    className="checkbox-input"
                                  />
                                  <span className="checkbox-tile">
                                    <span className="checkbox-label">Yes</span>
                                  </span>
                                </label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td scope="row">Identify potential issues</td>

                            <td className="text-center">
                              <div className="checkbox custom-service">
                                <label className="checkbox-wrapper">
                                  <input
                                    type="checkbox"
                                    className="checkbox-input"
                                  />
                                  <span className="checkbox-tile">
                                    <span className="checkbox-label">Yes</span>
                                  </span>
                                </label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="addon-box"
                    aria-hidden={selected !== "plus" ? true : false}
                  >
                    <h3 className="text-center mb-4">
                      Package selected : <span>Plus Service</span>
                    </h3>
                    <h4>Addons for Plus Service</h4>
                    <div className="table-responsive-lg">
                      <table className="table table-striped pricing-table">
                        <tbody>
                          <tr>
                            <td scope="row" className="border-0">
                              Proofread for errors
                            </td>

                            <td className="text-center">
                              <div className="checkbox custom-service">
                                <label className="checkbox-wrapper">
                                  <input
                                    type="checkbox"
                                    className="checkbox-input"
                                  />
                                  <span className="checkbox-tile">
                                    <span className="checkbox-label">Yes</span>
                                  </span>
                                </label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td scope="row">
                              Perform limited style guide edits
                            </td>
                            <td className="text-center">
                              <div className="checkbox custom-service">
                                <label className="checkbox-wrapper">
                                  <input
                                    type="checkbox"
                                    className="checkbox-input"
                                  />
                                  <span className="checkbox-tile">
                                    <span className="checkbox-label">Yes</span>
                                  </span>
                                </label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td scope="row">Fine-tune references</td>

                            <td className="text-center">
                              <div className="checkbox custom-service">
                                <label className="checkbox-wrapper">
                                  <input
                                    type="checkbox"
                                    className="checkbox-input"
                                  />
                                  <span className="checkbox-tile">
                                    <span className="checkbox-label">Yes</span>
                                  </span>
                                </label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td scope="row">Identify potential issues</td>

                            <td className="text-center">
                              <div className="checkbox custom-service">
                                <label className="checkbox-wrapper">
                                  <input
                                    type="checkbox"
                                    className="checkbox-input"
                                  />
                                  <span className="checkbox-tile">
                                    <span className="checkbox-label">Yes</span>
                                  </span>
                                </label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step4;
