import React, { useState, useEffect } from "react";
import "../../assets/css/pricing.css";
import Cookies from "universal-cookie";
import { axiosRequest } from "../../../api";
import { baseURL } from "../../../constant";

const Step2 = (props) => {
  const cookies = new Cookies();
  const isLogin = cookies.get("islogin");
  const userName = cookies.get("userName");
  const userEmail = cookies.get("userEmail");
  const [activeTab, setActiveTab] = useState(1);
  const [countryList, setCountryList] = useState([]);

  const handleTabClick = (tabIndex) => {
    if (tabIndex === 1) {
    } else if (tabIndex === 2) {
    }
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    const getCountry = async () => {
      const response = await axiosRequest.get(`${baseURL}/getCountryList`);
      setCountryList(response.data);
    };

    getCountry();
  }, []);

  // Trigger an alert on form submission

  if (props.currentStep !== 2) {
    return null;
  }
  return (
    <>
      <section className="pt-2 pb-2">
        <div className="container">
          {isLogin && isLogin === "true" ? (
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <div className="loginrequired mb-4">
                  <div className="login_info text-center">
                    <h4>Welcome {userName && userName}</h4>
                    <p>You can continue with this order</p>
                    <div className="row justify-content-center">
                      <div className="col-md-6 col-sm-12 col-12 ">
                        <div className="">
                          <span
                            style={{ fontWeight: "bold", marginRight: "10px" }}
                          >
                            User Name :
                          </span>
                          <span>{userName && userName}</span>
                        </div>
                        <div className="">
                          <span
                            style={{ fontWeight: "bold", marginRight: "10px" }}
                          >
                            Email Address :
                          </span>
                          <span>{userEmail && userEmail}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <div className="loginrequired mb-4">
                  <div className="login_info text-center">
                    <h4>Please sign up or log in</h4>
                    <p>
                      You need a WordsRU account to continue with this order
                    </p>

                    <div className="tabs mt-3">
                      <ul>
                        <li>
                          <a
                            href="javscript:void(0)"
                            className={activeTab == 1 ? "active-tab" : ""}
                            onClick={() => handleTabClick(1)}
                          >
                            Login
                          </a>
                        </li>
                        <li>
                          <a
                            href="javscript:void(0)"
                            className={activeTab == 2 ? "active-tab" : ""}
                            onClick={() => handleTabClick(2)}
                          >
                            Sign Up
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="tab-content">
                    {activeTab == 1 && (
                      <div>
                        <div className="row justify-content-center">
                          <div className="col-md-6 col-sm-12 col-12">
                            <div className="login_box">
                              <div className="form-group">
                                <label>Email Address</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Enter Email address"
                                  name="email"
                                  onChange={props.handleChange}
                                  required
                                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                />
                              </div>

                              <div className="form-group">
                                <label>Password</label>
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Enter your password"
                                  name="password"
                                  onChange={props.handleChange}
                                  required
                                  minLength="6"
                                />
                              </div>

                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  name="remember-me"
                                  id="remember-me"
                                  className="agree-term"
                                />
                                <label
                                  for="remember-me"
                                  className="label-agree-term"
                                >
                                  <span>
                                    <span></span>
                                  </span>
                                  Keep me signed for 2 weeks
                                </label>
                              </div>
                              <input
                                type="hidden"
                                name="formTab"
                                value="Login"
                              />
                              <div className="form-group mb-0">
                                <a
                                  className="forget-password-link"
                                  href="/login"
                                >
                                  Forgot Password?
                                </a>
                              </div>

                              <div className="form-group">
                                <button
                                  className="btn btn-primary"
                                  onClick={props.loginSubmit}
                                  data-city="Log in"
                                >
                                  Log in
                                </button>
                              </div>
                              {props.loginSuccess === "true" ? (
                                <div>
                                  <span>You are Logged-In Successfully!</span>
                                </div>
                              ) : (
                                  <div>
                                      {props.loginFail === "true" ? (
                                  <div>
                                  <span>Invalid login details</span>
                                  </div>
                                ) : (
                                  <div>
                               
                                  </div>
                                )}
                                  
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeTab === 2 && (
                      <div>
                        <div className="row justify-content-center">
                          <div className="col-md-6 col-sm-12 col-12">
                            <div className="login_box">
                              <div className="form-group">
                                <label htmlFor="name">Full name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your Name"
                                  name="name"
                                  onChange={props.handleChange}
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="email">Email Address</label>
                                <input
                                  validators={["required", "isEmail"]}
                                  errorMessages={[
                                    "this field is required",
                                    "email is not valid",
                                  ]}
                                  type="email"
                                  className="form-control"
                                  placeholder="Enter Email address"
                                  name="email"
                                  onChange={props.handleChange}
                                  required
                                />
                              </div>
                              <input
                                type="hidden"
                                name="formTab"
                                value="Signup"
                              />
                              <div className="form-group">
                                <label htmlFor="country">Country</label>
                                <select
                                  id="country"
                                  className="form-control"
                                  name="rcountry"
                                  onChange={props.handleChange}
                                  placeholder="Select Country"
                                >
                                  {countryList?.map((item, i) => (
                                    <option value={item?.id}>
                                      {item?.publicName}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="form-group">
                                <label htmlFor="email">
                                  Preferred Currency
                                </label>
                                <select
                                  id="currency"
                                  className="form-control"
                                  name="rcurrency"
                                >
                                  <option selected>Preferred Currency</option>
                                  <option>US Dollar</option>
                                  <option>Australian Dollar</option>
                                  <option>Pounds Sterling</option>
                                  <option>Hong Kong Dollar</option>
                                  <option>Euro</option>
                                  <option>Canadian Dollar</option>
                                </select>
                              </div>

                              <div className="form-group mb-2">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck1"
                                  />
                                  <label
                                    className="form-check-label label-agree-term"
                                    htmlFor="defaultCheck1"
                                  >
                                    I want to get newsletters, special offers
                                    and other interesting details.
                                  </label>
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck2"
                                  />
                                  <label
                                    className="form-check-label label-agree-term"
                                    htmlFor="defaultCheck2"
                                  >
                                    By clicking "Register" you agree to our
                                    Terms and Conditions and Privacy Statement.
                                  </label>
                                </div>
                              </div>

                              {/* <div className="form-group">
                              <input
                                type="submit"
                                name="signup"
                                id="signup"
                                className="btn btn-primary"
                                value="Register"
                              />
                            </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* Add more content sections for additional tabs */}
                  </div>
                </div>
                {activeTab === 3 && (
                  <div>
                    <div className="loginnot_required">
                      <div className="row justify-content-start">
                        <div className="col-md-6 col-sm-12 col-12">
                          <div className="form-group">
                            <label>Full Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter the name"
                              name="name"
                            />
                          </div>
                          <div className="form-group">
                            <label>Email Address</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter the name"
                              name="email"
                            />
                          </div>
                          <div className="form-group">
                            <label>Country</label>
                            <select className="form-control" name="country">
                              <option value="Afghanistan">Afghanistan</option>
                              <option value="Albania">Albania</option>
                              <option value="Algeria">Algeria</option>
                              <option value="American Samoa">
                                American Samoa
                              </option>
                              <option value="Andorra">Andorra</option>
                              <option value="Angola">Angola</option>
                              <option value="Anguilla">Anguilla</option>
                              <option value="Antartica">Antarctica</option>
                              <option value="Antigua and Barbuda">
                                Antigua and Barbuda
                              </option>
                              <option value="Argentina">Argentina</option>
                              <option value="Armenia">Armenia</option>
                              <option value="Aruba">Aruba</option>
                              <option value="Australia" selected>
                                Australia
                              </option>
                              <option value="Austria">Austria</option>
                              <option value="Azerbaijan">Azerbaijan</option>
                              <option value="Bahamas">Bahamas</option>
                              <option value="Bahrain">Bahrain</option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="Barbados">Barbados</option>
                              <option value="Belarus">Belarus</option>
                              <option value="Belgium">Belgium</option>
                              <option value="Belize">Belize</option>
                              <option value="Benin">Benin</option>
                              <option value="Bermuda">Bermuda</option>
                              <option value="Bhutan">Bhutan</option>
                              <option value="Bolivia">Bolivia</option>
                              <option value="Bosnia and Herzegowina">
                                Bosnia and Herzegowina
                              </option>
                              <option value="Botswana">Botswana</option>
                              <option value="Bouvet Island">
                                Bouvet Island
                              </option>
                              <option value="Brazil">Brazil</option>
                              <option value="British Indian Ocean Territory">
                                British Indian Ocean Territory
                              </option>
                              <option value="Brunei Darussalam">
                                Brunei Darussalam
                              </option>
                              <option value="Bulgaria">Bulgaria</option>
                              <option value="Burkina Faso">Burkina Faso</option>
                              <option value="Burundi">Burundi</option>
                              <option value="Cambodia">Cambodia</option>
                              <option value="Cameroon">Cameroon</option>
                              <option value="Canada">Canada</option>
                              <option value="Cape Verde">Cape Verde</option>
                              <option value="Cayman Islands">
                                Cayman Islands
                              </option>
                              <option value="Central African Republic">
                                Central African Republic
                              </option>
                              <option value="Chad">Chad</option>
                              <option value="Chile">Chile</option>
                              <option value="China">China</option>
                              <option value="Christmas Island">
                                Christmas Island
                              </option>
                              <option value="Cocos Islands">
                                Cocos (Keeling) Islands
                              </option>
                              <option value="Colombia">Colombia</option>
                              <option value="Comoros">Comoros</option>
                              <option value="Congo">Congo</option>
                              <option value="Congo">
                                Congo, the Democratic Republic of the
                              </option>
                              <option value="Cook Islands">Cook Islands</option>
                              <option value="Costa Rica">Costa Rica</option>
                              <option value="Cota D'Ivoire">
                                Cote d'Ivoire
                              </option>
                              <option value="Croatia">
                                Croatia (Hrvatska)
                              </option>
                              <option value="Cuba">Cuba</option>
                              <option value="Cyprus">Cyprus</option>
                              <option value="Czech Republic">
                                Czech Republic
                              </option>
                              <option value="Denmark">Denmark</option>
                              <option value="Djibouti">Djibouti</option>
                              <option value="Dominica">Dominica</option>
                              <option value="Dominican Republic">
                                Dominican Republic
                              </option>
                              <option value="East Timor">East Timor</option>
                              <option value="Ecuador">Ecuador</option>
                              <option value="Egypt">Egypt</option>
                              <option value="El Salvador">El Salvador</option>
                              <option value="Equatorial Guinea">
                                Equatorial Guinea
                              </option>
                              <option value="Eritrea">Eritrea</option>
                              <option value="Estonia">Estonia</option>
                              <option value="Ethiopia">Ethiopia</option>
                              <option value="Falkland Islands">
                                Falkland Islands (Malvinas)
                              </option>
                              <option value="Faroe Islands">
                                Faroe Islands
                              </option>
                              <option value="Fiji">Fiji</option>
                              <option value="Finland">Finland</option>
                              <option value="France">France</option>
                              <option value="France Metropolitan">
                                France, Metropolitan
                              </option>
                              <option value="French Guiana">
                                French Guiana
                              </option>
                              <option value="French Polynesia">
                                French Polynesia
                              </option>
                              <option value="French Southern Territories">
                                French Southern Territories
                              </option>
                              <option value="Gabon">Gabon</option>
                              <option value="Gambia">Gambia</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Germany">Germany</option>
                              <option value="Ghana">Ghana</option>
                              <option value="Gibraltar">Gibraltar</option>
                              <option value="Greece">Greece</option>
                              <option value="Greenland">Greenland</option>
                              <option value="Grenada">Grenada</option>
                              <option value="Guadeloupe">Guadeloupe</option>
                              <option value="Guam">Guam</option>
                              <option value="Guatemala">Guatemala</option>
                              <option value="Guinea">Guinea</option>
                              <option value="Guinea-Bissau">
                                Guinea-Bissau
                              </option>
                              <option value="Guyana">Guyana</option>
                              <option value="Haiti">Haiti</option>
                              <option value="Heard and McDonald Islands">
                                Heard and Mc Donald Islands
                              </option>
                              <option value="Holy See">
                                Holy See (Vatican City State)
                              </option>
                              <option value="Honduras">Honduras</option>
                              <option value="Hong Kong">Hong Kong</option>
                              <option value="Hungary">Hungary</option>
                              <option value="Iceland">Iceland</option>
                              <option value="India">India</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="Iran">
                                Iran (Islamic Republic of)
                              </option>
                              <option value="Iraq">Iraq</option>
                              <option value="Ireland">Ireland</option>
                              <option value="Israel">Israel</option>
                              <option value="Italy">Italy</option>
                              <option value="Jamaica">Jamaica</option>
                              <option value="Japan">Japan</option>
                              <option value="Jordan">Jordan</option>
                              <option value="Kazakhstan">Kazakhstan</option>
                              <option value="Kenya">Kenya</option>
                              <option value="Kiribati">Kiribati</option>
                              <option value="Democratic People's Republic of Korea">
                                Korea, Democratic People's Republic of
                              </option>
                              <option value="Korea">Korea, Republic of</option>
                              <option value="Kuwait">Kuwait</option>
                              <option value="Kyrgyzstan">Kyrgyzstan</option>
                              <option value="Lao">
                                Lao People's Democratic Republic
                              </option>
                              <option value="Latvia">Latvia</option>
                              <option value="Lebanon">Lebanon</option>
                              <option value="Lesotho">Lesotho</option>
                              <option value="Liberia">Liberia</option>
                              <option value="Libyan Arab Jamahiriya">
                                Libyan Arab Jamahiriya
                              </option>
                              <option value="Liechtenstein">
                                Liechtenstein
                              </option>
                              <option value="Lithuania">Lithuania</option>
                              <option value="Luxembourg">Luxembourg</option>
                              <option value="Macau">Macau</option>
                              <option value="Macedonia">
                                Macedonia, The Former Yugoslav Republic of
                              </option>
                              <option value="Madagascar">Madagascar</option>
                              <option value="Malawi">Malawi</option>
                              <option value="Malaysia">Malaysia</option>
                              <option value="Maldives">Maldives</option>
                              <option value="Mali">Mali</option>
                              <option value="Malta">Malta</option>
                              <option value="Marshall Islands">
                                Marshall Islands
                              </option>
                              <option value="Martinique">Martinique</option>
                              <option value="Mauritania">Mauritania</option>
                              <option value="Mauritius">Mauritius</option>
                              <option value="Mayotte">Mayotte</option>
                              <option value="Mexico">Mexico</option>
                              <option value="Micronesia">
                                Micronesia, Federated States of
                              </option>
                              <option value="Moldova">
                                Moldova, Republic of
                              </option>
                              <option value="Monaco">Monaco</option>
                              <option value="Mongolia">Mongolia</option>
                              <option value="Montserrat">Montserrat</option>
                              <option value="Morocco">Morocco</option>
                              <option value="Mozambique">Mozambique</option>
                              <option value="Myanmar">Myanmar</option>
                              <option value="Namibia">Namibia</option>
                              <option value="Nauru">Nauru</option>
                              <option value="Nepal">Nepal</option>
                              <option value="Netherlands">Netherlands</option>
                              <option value="Netherlands Antilles">
                                Netherlands Antilles
                              </option>
                              <option value="New Caledonia">
                                New Caledonia
                              </option>
                              <option value="New Zealand">New Zealand</option>
                              <option value="Nicaragua">Nicaragua</option>
                              <option value="Niger">Niger</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="Niue">Niue</option>
                              <option value="Norfolk Island">
                                Norfolk Island
                              </option>
                              <option value="Northern Mariana Islands">
                                Northern Mariana Islands
                              </option>
                              <option value="Norway">Norway</option>
                              <option value="Oman">Oman</option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Palau">Palau</option>
                              <option value="Panama">Panama</option>
                              <option value="Papua New Guinea">
                                Papua New Guinea
                              </option>
                              <option value="Paraguay">Paraguay</option>
                              <option value="Peru">Peru</option>
                              <option value="Philippines">Philippines</option>
                              <option value="Pitcairn">Pitcairn</option>
                              <option value="Poland">Poland</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Puerto Rico">Puerto Rico</option>
                              <option value="Qatar">Qatar</option>
                              <option value="Reunion">Reunion</option>
                              <option value="Romania">Romania</option>
                              <option value="Russia">Russian Federation</option>
                              <option value="Rwanda">Rwanda</option>
                              <option value="Saint Kitts and Nevis">
                                Saint Kitts and Nevis
                              </option>
                              <option value="Saint LUCIA">Saint LUCIA</option>
                              <option value="Saint Vincent">
                                Saint Vincent and the Grenadines
                              </option>
                              <option value="Samoa">Samoa</option>
                              <option value="San Marino">San Marino</option>
                              <option value="Sao Tome and Principe">
                                Sao Tome and Principe
                              </option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Senegal">Senegal</option>
                              <option value="Seychelles">Seychelles</option>
                              <option value="Sierra">Sierra Leone</option>
                              <option value="Singapore">Singapore</option>
                              <option value="Slovakia">
                                Slovakia (Slovak Republic)
                              </option>
                              <option value="Slovenia">Slovenia</option>
                              <option value="Solomon Islands">
                                Solomon Islands
                              </option>
                              <option value="Somalia">Somalia</option>
                              <option value="South Africa">South Africa</option>
                              <option value="South Georgia">
                                South Georgia and the South Sandwich Islands
                              </option>
                              <option value="Span">Spain</option>
                              <option value="SriLanka">Sri Lanka</option>
                              <option value="St. Helena">St. Helena</option>
                              <option value="St. Pierre and Miguelon">
                                St. Pierre and Miquelon
                              </option>
                              <option value="Sudan">Sudan</option>
                              <option value="Suriname">Suriname</option>
                              <option value="Svalbard">
                                Svalbard and Jan Mayen Islands
                              </option>
                              <option value="Swaziland">Swaziland</option>
                              <option value="Sweden">Sweden</option>
                              <option value="Switzerland">Switzerland</option>
                              <option value="Syria">
                                Syrian Arab Republic
                              </option>
                              <option value="Taiwan">
                                Taiwan, Province of China
                              </option>
                              <option value="Tajikistan">Tajikistan</option>
                              <option value="Tanzania">
                                Tanzania, United Republic of
                              </option>
                              <option value="Thailand">Thailand</option>
                              <option value="Togo">Togo</option>
                              <option value="Tokelau">Tokelau</option>
                              <option value="Tonga">Tonga</option>
                              <option value="Trinidad and Tobago">
                                Trinidad and Tobago
                              </option>
                              <option value="Tunisia">Tunisia</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Turkmenistan">Turkmenistan</option>
                              <option value="Turks and Caicos">
                                Turks and Caicos Islands
                              </option>
                              <option value="Tuvalu">Tuvalu</option>
                              <option value="Uganda">Uganda</option>
                              <option value="Ukraine">Ukraine</option>
                              <option value="United Arab Emirates">
                                United Arab Emirates
                              </option>
                              <option value="United Kingdom">
                                United Kingdom
                              </option>
                              <option value="United States">
                                United States
                              </option>
                              <option value="United States Minor Outlying Islands">
                                United States Minor Outlying Islands
                              </option>
                              <option value="Uruguay">Uruguay</option>
                              <option value="Uzbekistan">Uzbekistan</option>
                              <option value="Vanuatu">Vanuatu</option>
                              <option value="Venezuela">Venezuela</option>
                              <option value="Vietnam">Viet Nam</option>
                              <option value="Virgin Islands (British)">
                                Virgin Islands (British)
                              </option>
                              <option value="Virgin Islands (U.S)">
                                Virgin Islands (U.S.)
                              </option>
                              <option value="Wallis and Futana Islands">
                                Wallis and Futuna Islands
                              </option>
                              <option value="Western Sahara">
                                Western Sahara
                              </option>
                              <option value="Yemen">Yemen</option>
                              <option value="Serbia">Serbia</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Zimbabwe">Zimbabwe</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* <div className="col-12 mt-1">
                  <div className="">
                       <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            <label className="form-check-label" for="defaultCheck1">
                                 I want to get newsletters, special offers and other interesting details.
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                            <label className="form-check-label" for="defaultCheck2">
                                 you agree to our <Link to="/terms-and-conditions">Terms and Conditions</Link> and <Link to="/privacy-policy">Privacy Statement</Link>.
                            </label>
                          </div>
                       </div>
                  </div> */}

              {/* <div className="col-12 mt-1 text-center">
                                <div className="w-100">
                                    <div className="custom-control custom-checkbox mr-4">
                                        <input type="checkbox" className="custom-control-input" id="inlineCheckbox1" />
                                        <label className="custom-control-label" for="inlineCheckbox1">I want to get newsletters, special offers and other interesting details.</label>
                                    </div>
                                </div>
                                <div className="w-100">
                                    <div className="custom-control custom-checkbox mr-4">
                                        <input type="checkbox" className="custom-control-input" id="inlineCheckbox2" />
                                        <label className="custom-control-label" for="inlineCheckbox2">Agree to our <Link to="/terms-and-conditions">Terms and Conditions</Link> and <Link to="/privacy-policy">Privacy Statement</Link>.</label>
                                    </div>
                                </div>
                             
                            </div> */}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Step2;
