import React from "react";
import { Link, useNavigate } from "react-router-dom";
import userimg from "../assets/images/account.svg";
import searchimg from "../assets/images/search.svg";
import Cookies from "universal-cookie";
function Navigation() {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const checkLogin = cookies.get("islogin");
  const userEmail = cookies.get("userEmail");
  const userName = cookies.get("userName");
  const logout = () => {
    cookies.remove("islogin");
    cookies.remove("comeFrom");
    cookies.remove("userId");
    cookies.remove("userEmail");
    cookies.remove("userName");
    navigate("/");
  };
  console.log(
    `https://staging.wordsru.com/backend/login?email=${userEmail}&login=1`
  );
  return (
    <>
      <ul className="navbar-nav">
        <li class="nav-item dropdown">
          <Link class="nav-link" to="/academics">
            For Academics
          </Link>
        </li>
        <li class="nav-item dropdown">
          <Link class="nav-link" to="/proof-reading">
            For Authors
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/business" className="nav-link">
            For Business
          </Link>
        </li>
        {/* <li class="nav-item dropdown">
                <Link class="nav-link" to="#" >For Businesses</Link>                
              </li> */}

        {/* <li class="nav-item dropdown">
                <Link class="nav-link dropdown-toggle" to="#" data-toggle="dropdown">Services</Link>
                <ul class="dropdown-menu">
                  <li><Link class="dropdown-item" to="/proof-reading">Proofreading</Link></li>
                  <li><Link class="dropdown-item" to="/proof-reading">Editing</Link></li>
                  <li><Link class="dropdown-item" to="/proof-reading">Formatting</Link></li>
                </ul>
              </li>                  */}
        {/* <li className="nav-item dropdown has-megamenu">
                          <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown"> Services </a>
                          <div className="dropdown-menu animate fade-down megamenu" role="menu">
                            <div className="row">
                              <div className="col-md-4 col-6">
                                <div className="col-megamenu">
                                  <h6 className="title">For Academics</h6>
                                  <ul className="list-unstyled">
                                    <li><Link to="#">Essay Editing</Link></li>
                                    <li><Link to="#">Dissertation Editing</Link></li>
                                    <li><Link to="#">Thesis Editing</Link></li>
                                    <li><Link to="#">Report Editing</Link></li>
                                    <li><Link to="#">Academic ESL</Link></li>
                                    <li><Link to="#">Research Paper Editing</Link></li>
                                    <li><Link to="#">Journal Article Editing</Link></li>
                                    <li><Link to="#">Personal Statement Editing</Link></li>
                                  </ul>
                                </div>  
                              </div> 
                              <div className="col-md-4 col-6">
                                <div className="col-megamenu">
                                  <h6 className="title">For Authors</h6>
                                  <ul className="list-unstyled">
                                    <li><Link to="#">Book Editing</Link></li>
                                    <li><Link to="#">Children's Book Editing</Link></li>
                                    <li><Link to="#">Young Adult Fiction Editing</Link></li>
                                    <li><Link to="#">Literary Fiction Editing</Link></li>
                                    <li><Link to="#">Biography & Autobiography Editing</Link></li>
                                    <li><Link to="#">Self-Help & How-To Editing</Link></li>
                                    <li><Link to="#">Textbook Editing</Link></li>
                                  </ul>
                                </div>  
                              </div> 
                              <div className="col-md-4 col-6">
                                <div className="col-megamenu">
                                  <h6 className="title">For Businesses</h6>
                                  <ul className="list-unstyled">
                                    <li><Link to="#">Training Manual Editing</Link></li>
                                    <li><Link to="#">Website Editing & Copy Writing</Link></li>
                                    <li><Link to="#">Advertising Copy</Link></li>
                                    <li><Link to="#">Presentation Editing</Link></li>
                                    <li><Link to="#">Business Plan Editing</Link></li>
                                    <li><Link to="#">Copywriting Services</Link></li>
                                    <li><Link to="#">Business Document Editing</Link></li>
                                    <li><Link to="#">Newsletter Editing</Link></li>
                                    <li><Link to="#">Corporate Accounts</Link></li>
                                  </ul>
                                </div> 
                              </div>    
                              
                            </div> 
                          </div>  
                        </li>     */}

        <li
          className="nav-item"
          onClick={cookies.set("loginFrom", "Header", { path: "/" })}
        >
          <Link className="nav-link" to="/pricing">
            Pricing
          </Link>
        </li>

        {/* <li className="nav-item">
                        <Link to="#" className="nav-link">Try Now</Link>
                        </li> */}
      </ul>
      <div className="dropdown d-inline-block">
        <ul className="icon-list">
        {checkLogin != "true" && (
          <li>
            <Link to="/login">
              {" "}
              <img src={userimg} alt="Account" className="img-fluid" />{" "}
            </Link>
          </li>
          )}
          <li>
            <Link to="#" className="open-box">
              {" "}
              <img src={searchimg} className="img-fluid" alt="Searching" />{" "}
            </Link>
          </li>
        </ul>
      </div>
      
      
      
      {checkLogin == "true" && (

<div class="dropdown btn btn-dark ml-3">
<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  {userName}
</button>
<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
  <a className="dropdown-item" href="#" onClick={() =>
            window.location.replace(
              `https://staging.wordsru.com/backend/login?email=${userEmail}&login=1`
            )
          }>My Account</a>
  <a className="dropdown-item" href="#" onClick={logout}>
          Logout
        </a>
</div>
</div>
       
      )}
      
    </>
  );
}

export default Navigation;
