import React, { useState, useEffect,useRef ,useRefuseCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { axiosRequest } from "../../api";
import { baseURL, ADMINURL } from "../../constant";
import ReactHtmlParser from "react-html-parser";
import Cookies from "universal-cookie";
import moment from "moment/moment";

/*import { Link } from "react-router-dom";
import { Navigation, Autoplay  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import BrandsSection from "../common/brands";*/

function Home() {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [homecontent, setHomeContent] = useState([]);
  const cookies = new Cookies();

  useEffect(() => {
    const loadPost = async () => {
      // Till the data is fetch using API
      // the Loading page will show.
      setLoading(true);
      // Await make wait until that
      // promise settles and return its result
      const response = await axiosRequest.get(`${baseURL}/slider`);

      // After fetching data stored it in posts state.
      setPosts(response.data);

      // Closed the loading page
      setLoading(false);
    };

    // Call the function
    loadPost();
  }, []);

  useEffect(() => {
    const loadPost = async () => {
      // Till the data is fetch using API
      // the Loading page will show.
      setLoading(true);
      // Await make wait until that
      // promise settles and return its result
      const response = await axiosRequest.get(`${baseURL}/homecontent`);

      // After fetching data stored it in posts state.
      setHomeContent(response.data);

      // Closed the loading page
      setLoading(false);
    };

    // Call the function
    loadPost();
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  const [file, setFileName] = useState([]);
  const [isFile, setIsFile] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const handleFileChange = (e) => {
    setSelectedFiles([...e.target.files]);
    if (e.target.files) {
      setFileName(e.target.files[0]);
      setIsFile([...e.target.files]);
    }
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFileName(e.target.files[0]);
      setIsFile([...e.target.files]);
    }
  };
  const formData = new FormData();
  Array.from(selectedFiles).forEach((file) => {
    formData.append('images[]', file);
  });
  
  formData.append(`name`, name);
  formData.append(`email`, email);
  const data = {
    name: name,
    file: file,
    email: email,
  };
  
  let handleSubmit = async (e) => {

  
    
    e.preventDefault();

    await axiosRequest
      .post(`${baseURL}/submitQuoteSampleJob`, formData, config)
      .then((response) => {
        console.log(response);
        if (response.data.success === true) {
          setName("");
          setEmail("");
          setSelectedFiles([]);
          if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input
          }
          setMessage(response.data.message);
        } else {
          setMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Function to calculate the next day's date
  // const getNextDayDate = () => {
  //   const currentDate = new Date();
  //   currentDate.setDate(currentDate.getDate() + 2); // Add one day to the current date
  //   const year = currentDate.getFullYear();
  //   const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-based
  //   const day = String(currentDate.getDate()).padStart(2, "0");
  //   return `${day}-${month}-${year}`;
  // };
  // const [deliverydate, setDeliverydate] = useState(getNextDayDate());
  const [deliverydate, setDeliverydate] = useState();

  // const [deliverydate, setDeliverydate] = useState("30-07-2023");
  const [wordlength, setWordlength] = useState("250");
  const [price, setPrice] = useState("12.5");
  

  //const formdata = 'aaa';
  const handleChange = (event) => {
    cookies.set("serviceType", event.target.value, { path: "/" });
    // setDeliverydate(event.target.value);
    // 👇 Get input value from "event"
    if (event.target.value == 1) {
      //  setDeliverydate('30-07-2023');
      // let d = new Date();
      //  d.setTime(d.getTime() + (99*60*1000));
      //cookies.set('deliverydate', deliverydate, { path: '/' });
      // Cookies.set('deliverydate', deliverydate, { expires: 7 });
    } else if (event.target.value == 2) {
      //  Cookies.set('deliverydate', deliverydate, { expires: 7 });
      // cookies.set('deliverydate', deliverydate, { path: '/' });
      //  setDeliverydate('05-08-2023');
    } else {
      // Cookies.set('deliverydate', deliverydate, { expires: 7 });
      //  cookies.set('deliverydate', deliverydate, { path: '/' });
      //  setDeliverydate('10-08-2023');
    }
  };
  useEffect(() => {
    axiosRequest
      .get(`${baseURL}/getMaxDateBeforeExpedited/250/UTC`)
      .then(function (response) {
        const formattedDeliveryDate = moment(response.data).format(
          "DD MMM YYYY"
        );
        setDeliverydate(formattedDeliveryDate);
        cookies.set("deliverydate", response.data, { path: "/" });
      })
      .catch(function (error) {});
    const loadSetting = async () => {
      const response = await axiosRequest.get(`${baseURL}/getsetSetting`);
      cookies.set("setting", response.data, { path: "/" });
    };

    cookies.set("wordlength", "250", { path: "/" });
    cookies.set("price", "12.5", { path: "/" });
    cookies.set("selected", 1, { path: "/" });
    cookies.set("editorFees", "5", { path: "/" });
    cookies.set("ratio", "1", { path: "/" });
    cookies.set("jobType", "Basic Service", { path: "/" });
    loadSetting();
  }, []);

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleChangeText = (event) => {
    const wordCount = event.target.value;
    let pp;
    let ratio;
    let editorFees;
    if (wordCount >= 0 && wordCount <= 250) {
      pp = 12.5;
      ratio = 1;
      editorFees = 5;
    } else if (wordCount > 250 && wordCount <= 500) {
      pp = 25;
      ratio = 1;
      editorFees = 10;
    } else if (wordCount > 500 && wordCount <= 1000) {
      pp = 35;
      ratio = 1;
      editorFees = 12.5;
    } else if (wordCount > 1000 && wordCount <= 1500) {
      pp = 40;
      ratio = 1;
      editorFees = 14;
    } else if (wordCount >= 1500 && wordCount <= 2500) {
      pp = wordCount * 0.008 * 3.25;
      ratio = 3.25;
      editorFees = wordCount * 0.008;
    } else if (wordCount >= 2501 && wordCount <= 25000) {
      pp = wordCount * 0.007 * 3.2;
      ratio = 3.2;
      editorFees = wordCount * 0.007;
    } else if (wordCount >= 25001 && wordCount <= 50000) {
      pp = wordCount * 0.007 * 3;
      ratio = 3;
      editorFees = wordCount * 0.007;
    } else if (wordCount > 50000) {
      pp = wordCount * 0.007 * 2.9;
      ratio = 2.9;
      editorFees = wordCount * 0.007;
    } else {
      pp = 0;
      ratio = 1;
      editorFees = 5;
    }
    //for the last 2 decimal
    pp = pp.toFixed(2);

    setPrice(pp, ratio, editorFees);
    setWordlength(event.target.value);
    cookies.set("wordlength", event.target.value, { path: "/" });
    cookies.set("price", pp, { path: "/" });
    cookies.set("ratio", ratio, { path: "/" });
    cookies.set("editorFees", editorFees, { path: "/" });
    cookies.set("homePrice", pp, { path: "/" });
    // loadDelevirydate();
    axiosRequest
      .get(
        `${baseURL}/getMaxDateBeforeExpedited/` + event.target.value + `/UTC`
      )
      .then(function (response) {
        const formattedDeliveryDate = moment(response.data).format(
          "DD MMM YYYY HH:mm"
        );
        setDeliverydate(formattedDeliveryDate);
        cookies.set("deliverydate", response.data, { path: "/" });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // const datestr = deliverydate;
  // console.log(new Date(datestr).toISOString(), 182);

  // useEffect(() => {
  //   // Parse the current date string using the provided format
  //   const parsedDate = moment(deliverydate, "ddd MMM DD YYYY HH:mm:ss ZZ");
  //   console.log(parsedDate.format("DD MMM YYYY HH:mm "));

  //   // Format the parsed date to the desired format
  //   const formattedDate = parsedDate.format("DD MMM YYYY HH:mm");

  //   console.log(171, formattedDate); // Output the formatted date to the console
  // }, [deliverydate]);

  return (
    <>
      <section className="banner-section">
        <div
          id="carouselExampleCaptions"
          className="carousel slide"
          data-ride="carousel"
        >
          {/* <ol className="carousel-indicators">
                    <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
                    <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>                  
                  </ol> */}

          <div className="carousel-inner">
            {loading ? (
              <h4>Loading...</h4>
            ) : (
              posts.map((item, i) => (
                <>
                  <div className={`carousel-item ${i === 1 && "active"}`}>
                    <img
                      src={ADMINURL + item.image}
                      className="d-block w-100"
                      alt=" "
                    />
                  </div>
                </>
              ))
            )}
          </div>
          <div
            className="carousel-control-prev"
            data-target="#carouselExampleCaptions"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </div>
          <div
            className="carousel-control-next"
            data-target="#carouselExampleCaptions"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </div>
        </div>

        <div className="instant-quote">
          <div className="container">
            <div className="row justify-content-start">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="quote-form">
                  <h4>
                    Instant <span>Quote</span>
                  </h4>
                  <div className="row">
                    <div className="col-md-6 col-sm-12 col-12">
                      <div className="form-group">
                        <label>Service Type</label>
                        <select
                          className="form-control"
                          onChange={handleChange}
                        >
                          <option value="1" selected>
                            Editing
                          </option>
                          <option value="2">Proofreading</option>
                          <option value="3">Formating</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12">
                      <div className="form-group">
                        <label>Delivery Date</label>
                        <input
                          type="text"
                          className="form-control"
                          value={deliverydate}
                          disabled
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12 col-12">
                      <div className="form-group">
                        <label>Length</label>
                        <input
                          type="text"
                          value={wordlength}
                          className="form-control"
                          placeholder="1100 words"
                          onChange={handleChangeText}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12">
                      <div className="btn-main-box">
                        <div className="form-group">
                          <label>Price</label>
                          <span>${price}</span>
                        </div>

                        <div className="btn-box">
                          <Link
                            deliverydate={deliverydate}
                            to={{
                              pathname: "/quote",
                              state: { id: 1, name: "sabaoon", shirt: "green" },
                            }}
                            className="btn btn-dark"
                          >
                            Get Started
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Get free sample form section start here */}
      <section className="getform-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="below-banner">
                <div className="imageslist">
                  <ul>
                    <li>Excellent</li>
                    <li>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/star-review.png"
                        }
                        alt=""
                      />
                    </li>
                    <li>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/shoppers.png"
                        }
                        alt=""
                      />
                    </li>
                  </ul>
                </div>
                <div className="getform-box">
                  <div className="row">
                    <div className="col-md-8 col-sm-12 col-12">
                      <h3>{ReactHtmlParser(homecontent.form_title)}</h3>
                    </div>
                    <div className="col-md-4 col-sm-12 col-12 text-lg-right">
                      <Link to="/get-free-sample" className="btn-custom">
                        Free sample available for jobs
                      </Link>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit} enctype="multipart/form-data">
                    <div className="row mt-4">
                      <div className="col-3">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Your Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group">
                          <label className="upload-label">
                            Upload file <span>or</span>{" "}
                            <span className="free-sample">Enter Free Text</span>{" "}
                          </label>
                          <input
                          className="upload"
                          ref={fileInputRef}
                            type="file"
                            multiple
                            onChange={handleFileChange}
                          />
                          
                          <div className="mt-3">
                            {selectedFiles.length > 0 && (
                              <ul>
                                {selectedFiles.map((file, index) => (
                                  <li className="text-green" key={index}> {file.name} &nbsp; 
                                   <button className="text-red" onClick={() => handleRemoveFile(index)}>Remove</button>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                           
                        </div>
                      </div>
                      <div className="col-2">
                        <button
                          type="submit"
                          className="btn btn-dark rounded-0 w-100"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="message">
                    {message ? <p>{message}</p> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Get free sample form section start here */}

      {/* Professional Editing & Proofreading Services section start here */}
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-5">
              <div className="section_title">
                <h3> {ReactHtmlParser(homecontent.services_title)}</h3>
              </div>
            </div>
            <div className="col-12 text-center mb-4">
              {ReactHtmlParser(homecontent.services_description)}
            </div>

            <div className="col-md-4 col-sm-12 col-12">
              <div className="service_box">
                <div className="icon-box">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/proofreading.png"
                    }
                    alt=""
                  />
                  <h4>Proofreading</h4>
                </div>
                <div className="content">
                  <p>
                    WordsRU can assist you with editing a variety of academic
                    and scholarly documents, such as conference papers,
                    monographs, grant
                  </p>
                  <ul>
                    <li>
                      <i className="fa-solid fa-circle-dot"></i>Essay Editing
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-dot"></i>Dissertation
                      Editing
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-dot"></i>Thesis Editing
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-dot"></i>Report Editing
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-dot"></i>Academic ESL
                    </li>
                  </ul>
                </div>
                <Link to="/quote" className="getquote">
                  Get Quote <i className="fa-solid fa-arrow-right mr-2"></i>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 col-12">
              <div className="service_box">
                <div className="icon-box">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/fomating.png"}
                    alt=""
                  />
                  <h4>
                    Document <span>Formatting</span>
                  </h4>
                </div>
                <div className="content">
                  <p>
                    WordsRU can assist you with editing a variety of academic
                    and scholarly documents, such as conference papers,
                    monographs, grant
                  </p>
                  <ul>
                    <li>
                      <i className="fa-solid fa-circle-dot"></i>Essay Editing
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-dot"></i>Dissertation
                      Editing
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-dot"></i>Thesis Editing
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-dot"></i>Report Editing
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-dot"></i>Academic ESL
                    </li>
                  </ul>
                </div>
                <Link to="/quote" className="getquote">
                  Get Quote <i className="fa-solid fa-arrow-right mr-2"></i>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 col-12">
              <div className="service_box">
                <div className="icon-box">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/copy-editing.png"
                    }
                    alt=""
                  />
                  <h4>Copy Editing</h4>
                </div>
                <div className="content">
                  <p>
                    WordsRU can assist you with editing a variety of academic
                    and scholarly documents, such as conference papers,
                    monographs, grant
                  </p>
                  <ul>
                    <li>
                      <i className="fa-solid fa-circle-dot"></i>Essay Editing
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-dot"></i>Dissertation
                      Editing
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-dot"></i>Thesis Editing
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-dot"></i>Report Editing
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-dot"></i>Academic ESL
                    </li>
                  </ul>
                </div>
                <Link to="/quote" className="getquote">
                  Get Quote <i className="fa-solid fa-arrow-right mr-2"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Professional Editing & Proofreading Services section end here */}

      <section className="trusted-partner">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section_title mb-5 text-center">
                <h3>{ReactHtmlParser(homecontent.trusted_by_title)}</h3>
              </div>
              <Swiper
                navigation={true}
                modules={[Autoplay, Navigation]}
                spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 30,
                  },
                }}
                loop={true}
                className="mySwiper-brands"
              >
                <SwiperSlide>
                  <div className="img-box">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/philips.png"
                      }
                      alt=" "
                      className="img-fluid"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="img-box">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/horvard.png"
                      }
                      alt=" "
                      className="img-fluid"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="img-box">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/philips.png"
                      }
                      alt=" "
                      className="img-fluid"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="img-box">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/horvard.png"
                      }
                      alt=" "
                      className="img-fluid"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="img-box">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/philips.png"
                      }
                      alt=" "
                      className="img-fluid"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="img-box">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/horvard.png"
                      }
                      alt=" "
                      className="img-fluid"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding customer-section">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-5 text-center">
              <div className="section_title">
                <h3 className="">
                  {ReactHtmlParser(homecontent.customers_say_title)}
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/images/customer-review.png"
                }
                alt=""
                className="img-fluid"
              />
              <p> {ReactHtmlParser(homecontent.customers_say_text)}</p>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12 col-12">
              <Swiper
                modules={[Autoplay, Navigation]}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                loop={true}
                spaceBetween={30}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                className="mySwiper testimonial-slider"
              >
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="d-block d-md-flex ratingbox align-items-center mb-4">
                      <div className="font-10">
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                      </div>
                      <span className="thumb-img">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/verify-customer.png"
                          }
                          alt=" "
                          className="rounded-circle"
                        />
                      </span>
                    </div>

                    <div className="font-weight-light content mb-3">
                      The editor has met the deadline which I am satisfied with!
                    </div>
                    <h6 className="mb-0 customer">John Doe, Headmistress</h6>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="d-block d-md-flex ratingbox align-items-center mb-4">
                      <div className="font-10">
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                      </div>
                      <span className="thumb-img">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/verify-customer.png"
                          }
                          alt=" "
                          className="rounded-circle"
                        />
                      </span>
                    </div>

                    <div className="font-weight-light content mb-3">
                      The editor has met the deadline which I am satisfied with!
                    </div>
                    <h6 className="mb-0 customer">John Doe, Headmistress</h6>
                  </div>{" "}
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="d-block d-md-flex ratingbox align-items-center mb-4">
                      <div className="font-10">
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                      </div>
                      <span className="thumb-img">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/verify-customer.png"
                          }
                          alt=" "
                          className="rounded-circle"
                        />
                      </span>
                    </div>

                    <div className="font-weight-light content mb-3">
                      The editor has met the deadline which I am satisfied with!
                    </div>
                    <h6 className="mb-0 customer">John Doe, Headmistress</h6>
                  </div>{" "}
                </SwiperSlide>

                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="d-block d-md-flex ratingbox align-items-center mb-4">
                      <div className="font-10">
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                        <Link to="#" className="text-warning">
                          <i className="fa-solid fa-star"></i>
                        </Link>
                      </div>
                      <span className="thumb-img">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/verify-customer.png"
                          }
                          alt=" "
                          className="rounded-circle"
                        />
                      </span>
                    </div>

                    <div className="font-weight-light content mb-3">
                      The editor has met the deadline which I am satisfied with!
                    </div>
                    <h6 className="mb-0 customer">John Doe, Headmistress</h6>
                  </div>{" "}
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-5">
              <div className="section_title ">
                <h3 className="left_title">
                  {ReactHtmlParser(homecontent.how_it_works_title)}{" "}
                </h3>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 col-12">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/work.png"}
                className="img-fluid w-100"
                alt=""
              />
            </div>

            <div className="col-md-8 col-sm-12 col-12">
              <div className="work-box">
                <div className="img-box pt-1">
                  {" "}
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/upload.png"}
                    alt=""
                  />
                </div>
                <div className="content">
                  {ReactHtmlParser(homecontent.how_it_works_one)}
                </div>
              </div>

              <div className="work-box">
                <div className="img-box">
                  {" "}
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/payment.png"}
                    alt=""
                  />
                </div>
                <div className="content">
                  {ReactHtmlParser(homecontent.how_it_works_two)}
                </div>
              </div>

              <div className="work-box">
                <div className="img-box">
                  {" "}
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/editor.png"}
                    alt=""
                  />
                </div>
                <div className="content">
                  {ReactHtmlParser(homecontent.how_it_works_three)}
                </div>
              </div>

              <div className="work-box">
                <div className="img-box pt-2">
                  {" "}
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/collaborate.png"
                    }
                    alt=""
                  />
                </div>
                <div className="content">
                  {ReactHtmlParser(homecontent.how_it_works_four)}
                </div>
              </div>

              <div className="work-box">
                <div className="img-box pt-1">
                  {" "}
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/delivery.png"}
                    alt=""
                  />
                </div>
                <div className="content">
                  {ReactHtmlParser(homecontent.how_it_works_five)}
                </div>
              </div>

              <div className="work-box">
                <div className="img-box pt-1">
                  {" "}
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/feedback.png"}
                    alt=""
                  />
                </div>
                <div className="content">
                  {ReactHtmlParser(homecontent.how_it_works_six)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding section-expert">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-5">
              <div className="section_title">
                <h3>
                  {ReactHtmlParser(homecontent.our_expert_editors_title)}{" "}
                </h3>
              </div>
              <div className="View_all">
                <Link to="/team">View All</Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Swiper
                modules={[Autoplay, Navigation]}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                loop={true}
                spaceBetween={30}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                className="expert_team "
              >
                <SwiperSlide>
                  <div className="team-box editorcard">
                    <div className="top-profile-info">
                      <div className="img-box">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/team-01.png"
                          }
                          alt=""
                          className="editorimage"
                        />
                      </div>
                      <div className="content">
                        <h4>Aimée</h4>
                        <div className="d-block d-md-flex ratingbox align-items-center">
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                        </div>
                        <span className="mainedu">PhD in Epidemiology</span>
                        <span className="edudetail">10+ Years of Exp</span>
                        <span className="edudetail">Jobs Completed : 52</span>
                      </div>
                    </div>
                    <div className="skills-info">
                      <h3>Skills</h3>
                      <ul className="skill-list">
                        <li>
                          <Link to="/">Epidemiology</Link>
                        </li>
                        <li>
                          <Link to="/">Public Health </Link>
                        </li>
                        <li>
                          <Link to="/">Biology</Link>
                        </li>
                        <li>
                          <Link to="/">Government Documents</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="team-box editorcard">
                    <div className="top-profile-info">
                      <div className="img-box">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/team-01.png"
                          }
                          className="editorimage"
                          alt=""
                        />
                      </div>
                      <div className="content">
                        <h4>Aimée</h4>
                        <div className="d-block d-md-flex ratingbox align-items-center">
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                        </div>
                        <span className="mainedu">PhD in Epidemiology</span>
                        <span className="edudetail">10+ Years of Exp</span>
                        <span className="edudetail">Jobs Completed : 52</span>
                      </div>
                    </div>
                    <div className="skills-info">
                      <h3>Skills</h3>
                      <ul className="skill-list">
                        <li>
                          <Link to="/">Epidemiology</Link>
                        </li>
                        <li>
                          <Link to="/">Public Health </Link>
                        </li>
                        <li>
                          <Link to="/">Biology</Link>
                        </li>
                        <li>
                          <Link to="/">Government Documents</Link>
                        </li>
                      </ul>
                    </div>
                  </div>{" "}
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="team-box editorcard">
                    <div className="top-profile-info">
                      <div className="img-box">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/team-01.png"
                          }
                          className="editorimage"
                          alt=""
                        />
                      </div>
                      <div className="content">
                        <h4>Aimée</h4>
                        <div className="d-block d-md-flex ratingbox align-items-center">
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                        </div>
                        <span className="mainedu">PhD in Epidemiology</span>
                        <span className="edudetail">10+ Years of Exp</span>
                        <span className="edudetail">Jobs Completed : 52</span>
                      </div>
                    </div>
                    <div className="skills-info">
                      <h3>Skills</h3>
                      <ul className="skill-list">
                        <li>
                          <Link to="/">Epidemiology</Link>
                        </li>
                        <li>
                          <Link to="/">Public Health </Link>
                        </li>
                        <li>
                          <Link to="/">Biology</Link>
                        </li>
                        <li>
                          <Link to="/">Government Documents</Link>
                        </li>
                      </ul>
                    </div>
                  </div>{" "}
                </SwiperSlide>

                <SwiperSlide>
                  {" "}
                  <div className="team-box editorcard">
                    <div className="top-profile-info">
                      <div className="img-box">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/team-01.png"
                          }
                          className="editorimage"
                          alt=""
                        />
                      </div>
                      <div className="content">
                        <h4>Aimée</h4>
                        <div className="d-block d-md-flex ratingbox align-items-center">
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                          <Link to="#" className="text-warning">
                            <i className="fa-solid fa-star"></i>
                          </Link>
                        </div>
                        <span className="mainedu">PhD in Epidemiology</span>
                        <span className="edudetail">10+ Years of Exp</span>
                        <span className="edudetail">Jobs Completed : 52</span>
                      </div>
                    </div>
                    <div className="skills-info">
                      <h3>Skills</h3>
                      <ul className="skill-list">
                        <li>
                          <Link to="/">Epidemiology</Link>
                        </li>
                        <li>
                          <Link to="/">Public Health </Link>
                        </li>
                        <li>
                          <Link to="/">Biology</Link>
                        </li>
                        <li>
                          <Link to="/">Government Documents</Link>
                        </li>
                      </ul>
                    </div>
                  </div>{" "}
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      {/* See What you Get section display here */}
      <section className="section-padding correction-section">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-4">
              <div className="section_title">
                <h3>{ReactHtmlParser(homecontent.see_what_you_get_title)}</h3>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-4">
              <Tabs>
                <TabList>
                  <Tab>Basic</Tab>
                  <Tab>Plus</Tab>
                  <Tab>Custom</Tab>
                </TabList>

                <TabPanel>
                  <div className="row">
                    <div className="col-md-7 col-sm-6 col-6">
                      <div className="given-text">
                        Lorem ipsum dolor sit amet,{" "}
                        <span>consectetur adipisicing elit,</span> sed do
                        eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Lorem Ipsum is simply dummy text of the printing
                        and <span>typesetting industry</span>. Lorem Ipsum has
                        been the industry's <span>standard dummy text </span>
                        ever since the 1500s, it to make a type specimen book.t
                        has survived <span>not only five centuries</span>, but
                        also the leap into electronic typesetting, remaining{" "}
                        <span>essentially</span> unchanged. It was popularised
                        in the 1960s with the release of Letraset sheets
                        containing publishing software like Aldus{" "}
                        <span>PageMaker including</span> versions of Lorem Ipsum
                        remaining essentially unchanged. It was popularised in
                        the 1960s with the release of <span>Letraset</span>{" "}
                        sheets containing publishing software like Aldus
                        PageMaker including versions of Lorem Ipsum has{" "}
                        <span>survived</span> not only five centuries, but also
                        the leap into electronic typesetting
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-6 col-6">
                      <div className="correct_information mb-4">
                        <div className="img-box">
                          {" "}
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/correction.png"
                            }
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h5>Deleted:</h5> Lorem ipsum dolor sit amet,
                          consectetur adipisicing elit, sed do eiusmod
                        </div>
                      </div>

                      <div className="correct_information mb-4">
                        <div className="img-box">
                          {" "}
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/correction.png"
                            }
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h5>Deleted:</h5> Lorem ipsum dolor sit amet,
                          consectetur adipisicing elit, sed do eiusmod
                        </div>
                      </div>

                      <div className="correct_information">
                        <div className="img-box">
                          {" "}
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/correction.png"
                            }
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h5>Deleted:</h5> Lorem ipsum dolor sit amet,
                          consectetur adipisicing elit, sed do eiusmod
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row">
                    <div className="col-md-7 col-sm-6 col-6">
                      <div className="given-text">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Lorem Ipsum is simply dummy text of
                        the printing and <span>typesetting industry</span>.
                        Lorem Ipsum has been the industry's{" "}
                        <span>standard dummy text </span>ever since the 1500s,
                        it to make a type specimen book.t has survived{" "}
                        <span>not only five centuries</span>, but also the leap
                        into electronic typesetting, remaining{" "}
                        <span>essentially</span> unchanged. It was popularised
                        in the 1960s with the release of Letraset sheets
                        containing publishing software like Aldus{" "}
                        <span>PageMaker including</span> versions of Lorem Ipsum
                        remaining essentially unchanged. It was popularised in
                        the 1960s with the release of <span>Letraset</span>{" "}
                        sheets containing publishing software like Aldus
                        PageMaker including versions of Lorem Ipsum has{" "}
                        <span>survived</span> not only five centuries, but also
                        the leap into electronic typesetting
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-6 col-6">
                      <div className="correct_information mb-4">
                        <div className="img-box">
                          {" "}
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/correction.png"
                            }
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h5>Deleted:</h5> Lorem ipsum dolor sit amet,
                          consectetur adipisicing elit, sed do eiusmod
                        </div>
                      </div>

                      <div className="correct_information mb-4">
                        <div className="img-box">
                          {" "}
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/correction.png"
                            }
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h5>Deleted:</h5> Lorem ipsum dolor sit amet,
                          consectetur adipisicing elit, sed do eiusmod
                        </div>
                      </div>

                      <div className="correct_information">
                        <div className="img-box">
                          {" "}
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/correction.png"
                            }
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h5>Deleted:</h5> Lorem ipsum dolor sit amet,
                          consectetur adipisicing elit, sed do eiusmod
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row">
                    <div className="col-md-7 col-sm-6 col-6">
                      <div className="given-text">
                        Lorem ipsum dolor sit amet. Lorem Ipsum has been the
                        industry's <span>standard dummy text </span>ever since
                        the 1500s, it to make a type specimen book.t has
                        survived <span>not only five centuries</span>, but also
                        the leap into electronic typesetting, remaining{" "}
                        <span>essentially</span> unchanged. It was popularised
                        in the 1960s with the release of Letraset sheets
                        containing publishing software like Aldus{" "}
                        <span>PageMaker including</span> versions of Lorem Ipsum
                        remaining essentially unchanged. It was popularised in
                        the 1960s with the release of <span>Letraset</span>{" "}
                        sheets containing publishing software like Aldus
                        PageMaker including versions of Lorem Ipsum has{" "}
                        <span>survived</span> not only five centuries, but also
                        the leap into electronic typesetting
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-6 col-6">
                      <div className="correct_information mb-4">
                        <div className="img-box">
                          {" "}
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/correction.png"
                            }
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h5>Deleted:</h5> Lorem ipsum dolor sit amet,
                          consectetur adipisicing elit, sed do eiusmod
                        </div>
                      </div>

                      <div className="correct_information mb-4">
                        <div className="img-box">
                          {" "}
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/correction.png"
                            }
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h5>Deleted:</h5> Lorem ipsum dolor sit amet,
                          consectetur adipisicing elit, sed do eiusmod
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </section>
      {/* See What you Get section display here */}
      {/* FAQ section display start here */}
      <section className="section-padding faq-section">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-4">
              <div className="section_title">
                <h3>{ReactHtmlParser(homecontent.faq_title)}</h3>
              </div>
            </div>
          </div>

          <div className="row  justify-content-center mt-3">
            <div className="col-md-4 col-sm-12 col-12 mb-4">
              <div className="custom-card">
                <Link to="/faq">
                  <div className="icon-box">
                    <i className="fa fa-globe"></i>
                  </div>
                  <h4>English as a Second Language</h4>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 col-12 mb-4">
              <div className="custom-card">
                <Link to="/faq">
                  <div className="icon-box">
                    <i className="fa fa-bank"></i>
                  </div>
                  <h4>Academic</h4>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 col-12 mb-4">
              <div className="custom-card">
                <Link to="/faq">
                  {" "}
                  <div className="icon-box">
                    <i className="fa fa-graduation-cap"></i>
                  </div>
                  <h4>Student</h4>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 col-12 mb-4">
              <div className="custom-card">
                <Link to="/faq">
                  {" "}
                  <div className="icon-box">
                    <i className="fa fa-book"></i>
                  </div>
                  <h4>Author</h4>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 col-12 mb-4">
              <div className="custom-card">
                <Link to="/faq">
                  <div className="icon-box">
                    <i className="fa fa-building"></i>
                  </div>
                  <h4>Business or Corporate</h4>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 col-12 mb-4">
              <div className="custom-card">
                <Link to="/faq">
                  {" "}
                  <div className="icon-box">
                    <i className="fa fa-user"></i>
                  </div>
                  <h4>Personal</h4>
                </Link>
              </div>
            </div>
          </div>

          {/* <div className="row justify-content-center">
                    <div className="col-md-8 col-sm-12 col-12">
                    <div className="accordion-section clearfix mt-3" aria-label="Question Accordions">
				  <div className="container">
				  
					  <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                          <div className="panel panel-default mb-3">
                              <div className="panel-heading p-3 " role="tab" id="heading0">
                                  <h3 className="panel-title">
                                      <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse0" aria-expanded="true" aria-controls="collapse0">
                                           What kind of academic work do you edit?
                                      </a>
                                  </h3>
                              </div>
                              <div id="collapse0" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading0">
                                  <div className="panel-body mb-4">
                                      <p>The time required for an edit depends on the length and condition of the project, but we will make every effort to accommodate your deadline. WordsRU editors can usually return documents of 2000 words or less in 24 hours (upon receipt of payment), we also offer expedited service if you need an edit done more quickly.</p>

                                  </div>
                              </div>
                          </div>

                          <div className="panel panel-default  mb-3">
                              <div className="panel-heading p-3" role="tab" id="heading1">
                                  <h3 className="panel-title">
                                      <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                      What kinds of errors will you fix?
                                      </a>
                                  </h3>
                              </div>
                              <div id="collapse1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading1">
                                  <div className="panel-body mb-4">
                                  <p>The time required for an edit depends on the length and condition of the project, but we will make every effort to accommodate your deadline. WordsRU editors can usually return documents of 2000 words or less in 24 hours (upon receipt of payment), we also offer expedited service if you need an edit done more quickly.</p>

                                  </div>
                              </div>
                          </div>


                          <div className="panel panel-default  mb-3">
                              <div className="panel-heading p-3 " role="tab" id="heading2">
                                  <h3 className="panel-title">
                                      <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse2" aria-expanded="true" aria-controls="collapse2">
                                      How quickly can you edit my paper?
                                      </a>
                                  </h3>
                              </div>
                              <div id="collapse2" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading2">
                                  <div className="panel-body mb-4">
                                  <p>The time required for an edit depends on the length and condition of the project, but we will make every effort to accommodate your deadline. WordsRU editors can usually return documents of 2000 words or less in 24 hours (upon receipt of payment), we also offer expedited service if you need an edit done more quickly.</p>
                                  <p>Please remember that many academic documents benefit from consultation between the editor and the author. This is especially so for documents written by less-experienced students or by students and academics who have difficulty expressing themselves in writing (such as those for whom English is a second language). The consultation process can add great value to your project, but it also takes time. You should also allow yourself at least a day to review the edited paper and make any revisions.</p>
                                  </div>
                              </div>
                          </div>


                            <div className="panel panel-default  mb-3">
                              <div className="panel-heading p-3" role="tab" id="heading3">
                                  <h3 className="panel-title">
                                      <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse3" aria-expanded="true" aria-controls="collapse3">
                                         How much will it cost me?
                                      </a>
                                  </h3>
                              </div>
                              <div id="collapse3" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading3">
                                  <div className="panel-body mb-4">
                                  <p>The time required for an edit depends on the length and condition of the project, but we will make every effort to accommodate your deadline. WordsRU editors can usually return documents of 2000 words or less in 24 hours (upon receipt of payment), we also offer expedited service if you need an edit done more quickly.</p>

                                  </div>
                              </div>
                          </div>        
					  
					                 </div>
				  
				                 </div>
		               		</div>
                    </div>
                </div> */}

          <div className="row">
            <div className="col-12 text-center mt-4">
              <Link to="/faq" className="btn btn-dark rounded-0">
                View All FAQs
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* FAQ section display end here */}

      {/* blog section display here */}
      <section className="section-padding blog-section pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-5">
              <div className="section_title">
                <h3>{ReactHtmlParser(homecontent.blog_title)}</h3>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 col-sm-12 col-12">
              <div className="blogbox">
                <div className="img-box">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/post-01.jpg"}
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="content">
                  <h4>
                    <Link to="/blogdetail">
                      Power of a simple illustration in brand story
                    </Link>
                  </h4>
                  <div className="short-description">
                    Podcasting operational change management inside of workflows
                    to establish...
                  </div>
                  <Link to="/blogdetail" className="custom_link">
                    Read More
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 col-12">
              <div className="blogbox">
                <div className="img-box">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/post-02.jpg"}
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="content">
                  <h4>
                    <Link to="/blogdetail">
                      Power of a simple illustration in brand story
                    </Link>
                  </h4>
                  <div className="short-description">
                    Podcasting operational change management inside of workflows
                    to establish...
                  </div>
                  <Link to="/blogdetail" className="custom_link">
                    Read More
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 col-12">
              <div className="blogbox">
                <div className="img-box">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/post-01.jpg"}
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="content">
                  <h4>
                    <Link to="/blogdetail">
                      Power of a simple illustration in brand story
                    </Link>
                  </h4>
                  <div className="short-description">
                    Podcasting operational change management inside of workflows
                    to establish...
                  </div>
                  <Link to="/blogdetail" className="custom_link">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-center  mt-4">
              <Link to="/blog" className="btn btn-dark rounded-0">
                View More
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* blog section display end here */}
    </>
  );
}

export default Home;
