import React from "react";
import { Link } from "react-router-dom";

function PageNotFound (){

    return (
        <>
        <section className="inner-page section-themebg">
            <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                    <h3>404 Page Not Found</h3>
                </div>
            </div>
            </div>
        </section>

        <section className="page_not_found">
              <div className="container">
                  <div className="row justify-content-center">
                       <div className="col-md-6 col-sm-12 col-12">
                        <div className="notfound">
                            <div class="notfound-404">
                                <h1>4<span>0</span>4</h1>
                            </div>
                                <h4 className="mb-4">Oop's sorry we can't find that page!</h4>
                                <Link to="/" className="btn btn-dark rounded-0">Back to Home</Link>
                           </div>     
                       </div>
                  </div>
              </div>
        </section>
        </>
    );
}

export default PageNotFound;