import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../assets/css/myaccount.css";
import { axiosRequest } from "../../api";
import { baseURL } from "../../constant";
import Cookies from "universal-cookie";
import { useEffect } from "react";

// import Cookies from "universal-cookie";

function UserLogin() {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const [currentDiv, setCurrentDiv] = useState(1);
  const [userDetails, setUserDetails] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [userSignupDetails, setUserSignUpDetails] = useState({
    mobileNumber: "6543245345",
    timeZones: "Asia/Calcutta",
  });
  const showDiv1 = () => setCurrentDiv(1);
  const showDiv2 = () => setCurrentDiv(2);
  const showDiv3 = () => setCurrentDiv(3);
  const loginFrom = cookies.get("loginFrom");
  const isLogin = cookies.get("islogin");
  const userName = cookies.get("userName");
  const userEmail = cookies.get("userEmail");
  // if(currentDiv === 1) {
  //     console.log("first");
  // }
  // if(currentDiv === 1) {
  //     let islogin = false;
  //     Cookies.set("islogin", islogin, { path: "/" });
  // } else {
  //     let islogin = true;
  //     Cookies.set("islogin", islogin, { path: "/" });
  // }

  // const [showFirstDiv, setShowFirstDiv] = useState(true);
  // const [showSecondDiv, setShowSecondDiv] = useState(false);

  // const handleFirstDivClick = () => {
  //     setShowFirstDiv(true);
  //     setShowSecondDiv(false);
  // };

  // const handleSecondDivClick = () => {
  //     setShowFirstDiv(false);
  //     setShowSecondDiv(true);
  // };
  useEffect(() => {
    const getCountry = async () => {
      const response = await axiosRequest.get(`${baseURL}/getCountryList`);
      setCountryList(response.data);
    };

    getCountry();
  }, []);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserDetails({ ...userDetails, [name]: value });
  };
  const login = (event) => {
    event.preventDefault();
    const data = userDetails;

    axiosRequest
      .post(`${baseURL}/login`, data)
      .then((response) => {
        if (
          response.data.success === true &&
          response.data.message === "User login successfully"
        ) {
          if (loginFrom === "Price") {
            navigate("/pricing");
            cookies.set("loginFrom", "", { path: "/" });
            cookies.set("comeFrom", "login", { path: "/" });
          } else if (loginFrom === "Header") {
            navigate("/");
            cookies.set("loginFrom", "", { path: "/" });
          }
          cookies.set("islogin", "true", { path: "/" });
          cookies.set("userName", response.data.name, { path: "/" });
          cookies.set("userEmail", response.data.email, { path: "/" });
          cookies.set("userId", response.data.userId, { path: "/" });
        } else {
          alert(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSignUpChange = (event) => {
    const { name, value } = event.target;
    setUserSignUpDetails({ ...userSignupDetails, [name]: value });
  };
  const signUp = (event) => {
    event.preventDefault();
    const data = userSignupDetails;

    axiosRequest
      .post(`${baseURL}/signup`, data)
      .then((response) => {
        if (
          response.data.success === true &&
          response.data.message === "User login successfully"
        ) {
          if (loginFrom === "Price") {
            navigate("/pricing");
            cookies.set("loginFrom", "", { path: "/" });
            cookies.set("comeFrom", "login", { path: "/" });
          } else if (loginFrom === "Header") {
            navigate("/");
            cookies.set("loginFrom", "", { path: "/" });
          }
          cookies.set("islogin", "true", { path: "/" });
          cookies.set("userName", response.data.name, { path: "/" });
          cookies.set("userEmail", response.data.email, { path: "/" });
          cookies.set("userId", response.data.userId, { path: "/" });
        } else {
          alert(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="combineform">
        {isLogin ? (
          <>
            {" "}
            <section className="sign-in">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-10 col-sm-12 col-12">
                    <div className="signin-content">
                      <h2 className="form-title text-center">
                        Welcome {userName}
                      </h2>
                      <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="">
                            <span
                              style={{
                                fontWeight: "bold",
                                marginRight: "10px",
                              }}
                            >
                              User Name :
                            </span>
                            <span>{userName && userName}</span>
                          </div>
                          <div className="">
                            <span
                              style={{
                                fontWeight: "bold",
                                marginRight: "10px",
                              }}
                            >
                              Email Address :
                            </span>
                            <span>{userEmail && userEmail}</span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Link to="/" className="btn btn-dark rounded-0 mt-5">
                          Back to Home
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            <div style={{ display: currentDiv === 1 ? "block" : "none" }}>
              <section className="sign-in">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-10 col-sm-12 col-12">
                      <div className="signin-content">
                        <div className="row justify-content-center">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="signin-form">
                              <h2 className="form-title">Sign In</h2>
                              <form
                                onSubmit={login}
                                className="register-form"
                                id="login-form"
                              >
                                <div className="form-group">
                                  <label htmlFor="youremail">
                                    <i className="fa-solid fa-user"></i>
                                  </label>
                                  <input
                                    type="email"
                                    name="email"
                                    id="youremail"
                                    placeholder="Your Email"
                                    onChange={(event) => handleChange(event)}
                                    required
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="your_pass">
                                    <i className="fa-solid fa-lock"></i>
                                  </label>
                                  <input
                                    type="password"
                                    name="password"
                                    id="your_pass"
                                    placeholder="Password"
                                    onChange={(event) => handleChange(event)}
                                    required
                                    minLength="6"
                                  />
                                </div>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    name="remember-me"
                                    id="remember-me"
                                    className="agree-term"
                                  />
                                  <label
                                    htmlFor="remember-me"
                                    className="label-agree-term"
                                  >
                                    <span>
                                      <span></span>
                                    </span>
                                    Keep me signed for 2 weeks
                                  </label>
                                </div>
                                <Link
                                  to="#"
                                  onClick={showDiv3}
                                  className="forget-password-link"
                                >
                                  Forgot Password
                                </Link>
                                <div className="form-group form-button">
                                  <input
                                    type="submit"
                                    name="signin"
                                    id="signin"
                                    className="form-submit"
                                    value="Log in"
                                  />
                                </div>
                              </form>
                              <Link
                                to="#"
                                onClick={showDiv2}
                                className="signup-image-link mt-3"
                              >
                                Create an account
                              </Link>
                            </div>
                          </div>
                          {/* <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-lg-5">
                        <div className="signin-image ">
                          <figure>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/signin-image.jpg"
                              }
                              alt=" "
                            />
                          </figure>
                        </div>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            {/* Sign up form  */}
            <div style={{ display: currentDiv === 2 ? "block" : "none" }}>
              <section className="signup">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-md-10 col-ms-12 col-12">
                      <div className="signup-content">
                        <div className="row justify-content-center">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="signup-form">
                              <h2 className="form-title">Sign up</h2>
                              <form
                                onSubmit={signUp}
                                className="register-form"
                                id="register-form"
                              >
                                <div className="form-group">
                                  <label htmlFor="name">
                                    <i className="fa-solid fa-user"></i>
                                  </label>
                                  <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Your Name"
                                    onChange={(event) =>
                                      handleSignUpChange(event)
                                    }
                                    required
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="email">
                                    <i className="fa-solid fa-envelope"></i>
                                  </label>
                                  <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Your Email"
                                    onChange={(event) =>
                                      handleSignUpChange(event)
                                    }
                                    required
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="pass">
                                    <i className="fa-solid fa-globe"></i>
                                  </label>
                                  <select
                                    id="country"
                                    name="country"
                                    className="form-control"
                                    onChange={(event) =>
                                      handleSignUpChange(event)
                                    }
                                  >
                                    {countryList?.map((item, i) => (
                                      <option key={i} value={item?.id}>
                                        {item?.publicName}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="currency">
                                    <i className="fa-solid fa-coins"></i>
                                  </label>

                                  <select
                                    id="currency"
                                    name="currency"
                                    className="form-control"
                                    onChange={(event) =>
                                      handleSignUpChange(event)
                                    }
                                  >
                                    <option selected>Preferred Currency</option>
                                    <option>US Dollar</option>
                                    <option>Australian Dollar</option>
                                    <option>Pounds Sterling</option>
                                    <option>Hong Kong Dollar</option>
                                    <option>Euro</option>
                                    <option>Canadian Dollar</option>
                                  </select>
                                </div>
                                <div className="form-group mb-2">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="defaultCheck1"
                                    />
                                    <label
                                      className="form-check-label label-agree-term"
                                      htmlFor="defaultCheck1"
                                    >
                                      I want to get newsletters, special offers
                                      and other interesting details.
                                    </label>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="defaultCheck2"
                                    />
                                    <label
                                      className="form-check-label label-agree-term"
                                      htmlFor="defaultCheck2"
                                    >
                                      By clicking "Register" you agree to our
                                      Terms and Conditions and Privacy
                                      Statement.
                                    </label>
                                  </div>
                                </div>
                                {/* <div className="form-group mb-2">
                                            <input type="checkbox" name="agree-term" id="agree-term"/>
                                            <label htmlFor="agree-term" className="label-agree-term"></label>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" name="agree-term" id="terms"/>
                                            <label htmlFor="terms" className="label-agree-term"></label>
                                        </div> */}

                                <div className="form-group form-button">
                                  <input
                                    type="submit"
                                    name="signup"
                                    id="signup"
                                    className="form-submit"
                                    value="Register"
                                  />
                                </div>
                              </form>

                              <Link
                                to="#"
                                onClick={showDiv1}
                                className="signup-image-link mt-2"
                              >
                                I am already member
                              </Link>
                            </div>
                          </div>
                          {/* <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-lg-5">
                        <figure>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/signup-image.jpg"
                            }
                            alt=" "
                          />
                        </figure>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div style={{ display: currentDiv === 3 ? "block" : "none" }}>
              <section className="sign-in">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-10 col-sm-12 col-12">
                      <div className="signin-content">
                        <div className="row justify-content-center">
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="signin-form">
                              <h2 className="form-title">Forgot Password</h2>
                              <form className="register-form" id="login-form">
                                <div className="form-group">
                                  <label htmlFor="youremail">
                                    <i className="fa-solid fa-user"></i>
                                  </label>
                                  <input
                                    type="email"
                                    name="youremail"
                                    id="youremail"
                                    placeholder="Your Email"
                                    required
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                  />
                                </div>

                                <Link
                                  to="#"
                                  onClick={showDiv1}
                                  className="forget-password-link"
                                >
                                  Back to Login
                                </Link>
                                <div className="form-group form-button">
                                  <input
                                    type="submit"
                                    name="signin"
                                    id="signin"
                                    className="form-submit"
                                    value="Submit"
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default UserLogin;
