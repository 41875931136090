import React, { Component } from "react";
import Step1 from "./submitjob/step1";
import Step2 from "./submitjob/step2";
import Step3 from "./submitjob/step3";
import Step4 from "./submitjob/step4";
import Step5 from "./submitjob/step5";
import Cookies from "universal-cookie";
import { axiosRequest } from "../../api";
import { baseURL } from "../../constant";
import { withRouter } from "../common/withRouter";
import ProgresQuote from "./submitjob/progressbar";

class GetaQuote extends Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    
    cookies.set("taxvalue", '0', { path: "/" });
    cookies.set("currency", '3', { path: "/" });
    // Set the intiial input values
    this.state = {
      currencyText: 'USD',
      currency: '3',
      taxAmount: '0',
      currentStep: 1,
      email: cookies.get("userEmail"),
      password: "",
      name: cookies.get("userName"),
      file: cookies.get("filecookies"),
      rcountry: "",
      country: "",
      wordlength: cookies.get("wordlength"),
      serviceType: cookies.get("serviceType"),
      jobType: "Basic Service",
      price: cookies.get("price"),
      deliverydate: cookies.get("deliverydate"),
      formtab: "",
      success: "false",
      loginSuccess: "false",
      loginFail: "false",
      ratio: cookies.get("ratio"),
      editorFees: cookies.get("editorFees"),
      userId: cookies.get("userId"),
      jobId: cookies.get("jobId"),
      fullAmountQuoted:
        parseInt(cookies.get("price")) +
        parseInt(cookies.get("setting").regular_delivery_amount) +
        parseInt(cookies.get("setting").tax_amount),
      isFileUploaded: "",
    };

    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this);
    // Bind the submission to uploadedFile()
    this.uploadedFile = this.uploadedFile.bind(this);
    // Bind the submission to Login()
    this.loginSubmit = this.loginSubmit.bind(this);
    // Bind the submission to JobType()
    this.getJobType = this.getJobType.bind(this);
    // Bind new functions for next and previous
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);

    
  }


    loadJS(FILE_URL, async = true) {
      
    let scriptEle = document.createElement("script");

    scriptEle.setAttribute("src", FILE_URL);
    scriptEle.setAttribute("type", "text/javascript");
    scriptEle.setAttribute("async", async);

    document.body.appendChild(scriptEle);

    // success event 
    scriptEle.addEventListener("load", () => {
      console.log("File loaded")
      
    });
    // error event
    scriptEle.addEventListener("error", (ev) => {
      console.log("Error on loading file", ev);
    });
  }
 
  // Use the submitted data to set the state
  handleChange(event) {
    const { name, value } = event.target;
    const cookies = new Cookies();
    console.log(name, value, 73);
    const curr = "USD";
	
	if(name === "name"){
		cookies.set("userName", value, { path: "/" });
	}
	if(name === "email"){
		cookies.set("userEmail", value, { path: "/" });
	}
    if(name === "currency"){
     
      if(value ==1){
       
        const curr = "AUD";
        var scriptsrc = 'https://www.paypal.com/sdk/js?client-id=ASf-1FjPx45SxHr0QI4iiRYjNijJGMr18MPo3uEvr4y6GqE64_mu1n7KyS0SeJb9lMc5TSkIUq7E78k5&currency='+curr;
        this.setState({
          currencyText: "AUD",
        });
      }else if(value ==2){
        console.log("curr---------")
        
        const curr = "GBP";
        var scriptsrc = 'https://www.paypal.com/sdk/js?client-id=ASf-1FjPx45SxHr0QI4iiRYjNijJGMr18MPo3uEvr4y6GqE64_mu1n7KyS0SeJb9lMc5TSkIUq7E78k5&currency='+curr;
        this.setState({
          currencyText: "GBP",
        });
      }else if(value ==3){
        const curr = "USD";
        var scriptsrc = 'https://www.paypal.com/sdk/js?client-id=ASf-1FjPx45SxHr0QI4iiRYjNijJGMr18MPo3uEvr4y6GqE64_mu1n7KyS0SeJb9lMc5TSkIUq7E78k5&currency='+curr;
        this.setState({
          currencyText: "USD",
        });
      }else if(value ==4){
        const curr = "HKD";
        var scriptsrc = 'https://www.paypal.com/sdk/js?client-id=ASf-1FjPx45SxHr0QI4iiRYjNijJGMr18MPo3uEvr4y6GqE64_mu1n7KyS0SeJb9lMc5TSkIUq7E78k5&currency='+curr;
        this.setState({
          currencyText: "HKD",
        });
      }else if(value ==5){
        const curr = "EUR";
        var scriptsrc = 'https://www.paypal.com/sdk/js?client-id=ASf-1FjPx45SxHr0QI4iiRYjNijJGMr18MPo3uEvr4y6GqE64_mu1n7KyS0SeJb9lMc5TSkIUq7E78k5&currency='+curr;
        this.setState({
          currencyText: "EUR",
        });
      }else if(value ==6){
        const curr = "CAD";
        var scriptsrc = 'https://www.paypal.com/sdk/js?client-id=ASf-1FjPx45SxHr0QI4iiRYjNijJGMr18MPo3uEvr4y6GqE64_mu1n7KyS0SeJb9lMc5TSkIUq7E78k5&currency='+curr;
        this.setState({
          currencyText: "CAD",
        });
      }

     
      this.loadJS(""+scriptsrc+"", true);


       cookies.set("currency", value, { path: "/" });
       if(value != "1"){
        cookies.set("taxvalue", '0', { path: "/" });
        this.setState({
          taxAmount: "0",
          changecurrency: value,
        });
       
      }else{
        //settaxAmount('0.00');
        cookies.set("taxvalue", '10', { path: "/" });
        this.setState({
          taxAmount: "10",
          changecurrency: value,
        });
      }
    }
    this.setState({
      [name]: value,
    });
  }

  // Trigger an alert on form submission
  handleSubmit = (event) => {
    event.preventDefault();
    const cookies = new Cookies();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    const data = this.state;

    axiosRequest
      .post(`${baseURL}/submitQuotePageJob`, data, config)
      .then((response) => {
        if (response.data.success == true) {
          if (response.data.jobId) {
            cookies.set("jobId", response.data.jobId, { path: "/" });
            this.setState({ jobId: response.data.jobId });
          }
          if (response.data.userId) {
            this.setState({ userId: response.data.userId });
          }
         
         
          if (response.data.userId) {
            cookies.set("islogin", "true", { path: "/" });
            cookies.set("userName", this.state.name, { path: "/" });
            cookies.set("userEmail", this.state.email, { path: "/" });
          }
          if (this.state.currentStep === 5) {
            this.props.navigate("/thank-you");
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loginSubmit = (event) => {
    event.preventDefault();
    const cookies = new Cookies();
    const data = this.state;

    axiosRequest
      .post(`${baseURL}/login`, data)
      .then((response) => {
        if (response.data.success === true) {
          cookies.set("islogin", "true", { path: "/" });
          cookies.set("userName", response.data.name, { path: "/" });
          cookies.set("userEmail", response.data.email, { path: "/" });
          cookies.set("userId", response.data.userId, { path: "/" });
          this.setState({ loginSuccess: "true" });
          this.setState({ userId: response.data.userId });
          this.setState({ formtab: "Login" });
          this.setState({ name: response.data.name });
        } else {
          this.setState({ loginFail: "true" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Test current step with ternary
  // _next and _previous functions will be called on button click
  _next() {
    let currentStep = this.state.currentStep;

    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 4 ? 5 : currentStep + 1;
    this.setState({
      currentStep: currentStep,
    });
  }

  _prev() {
    let currentStep = this.state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
  }

  uploadedFile(file) {
    this.setState({ file: file[0] });
    this.setState({ isFileUploaded: "true" });
  }

  getJobType(name) {
    const cookies = new Cookies();
    this.setState({ jobType: name });
    cookies.set("jobType", name, { path: "/" });
  }

  getTotalVal(val) {
    this.setState({ fullAmountQuoted: val });
  }

  // The "next" and "previous" button functions
  get previousButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (
        <button className="btn btn-outline-primary ml-3" onClick={this._prev}>
          Back
        </button>
      );
    }

    // ...else return nothing
    return null;
  }

  get nextButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 3, then render the "next" button
    if (currentStep < 5) {
      return (
        <button
          className="btn btn-primary mr-3"
          onClick={this._next}
          disabled={this.state.currentStep === 1 && !this.state.isFileUploaded}
        >
          Continue
        </button>
      );
    }
    // ...else render nothing
    return null;
  }

  get submitButton() {
    let currentStep = this.state.currentStep;

    // If the current step is the last step, then render the "submit" button
    if (currentStep > 4) {
      return <button className="btn btn-primary mr-3">Submit</button>;
    }
    // ...else render nothing
    return null;
  }

  componentDidUpdate(previousProps, previousState) {
    const cookies = new Cookies();
    let totalCalculatedAmount = cookies.get("fullAmount");
    if (
      previousState.fullAmountQuoted !== undefined &&
      previousState.fullAmountQuoted !== totalCalculatedAmount
    ) {
      this.getTotalVal(totalCalculatedAmount);
    }
    return;
  }

  render() {
    return (
      <>
        <section className="inner-page section-themebg">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h3>Get a Quote</h3>
              </div>
            </div>
          </div>
        </section>

        <section className="section-padding bg-gray">
          <div className="container">
            <div className="row">
              <div className="col-12">
      
                  <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12 col-sm-12 col-12 mb-5">
                      <div className="progress-box mb-5" data={this.state.currentStep}>
                        <ProgresQuote currentStep={this.state.currentStep} />
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-10 col-sm-12 col-12">
                      <div className="custom-card-form cutsom-padding">
                      <form onSubmit={this.handleSubmit}>
                        <Step1
                          currentStep={this.state.currentStep}
                          handleChange={this.handleChange}
                          getUploadedFile={this.uploadedFile}
                        />
                   
                        <Step2
                          currentStep={this.state.currentStep}
                          handleChange={this.handleChange}
                          loginSubmit={this.loginSubmit}
                          loginSuccess={this.state.loginSuccess}
                          loginFail={this.state.loginFail}
                        />
                        
                        <Step3
                          changecurrency={this.state.changecurrency}
                          currentStep={this.state.currentStep}
                          handleChange={this.handleChange}
                        />
                         
                        <Step4
                          currentStep={this.state.currentStep}
                          handleChange={this.handleChange}
                          selectedJobType={this.getJobType}
                        />
                          <div className="card-footer-button">
                          <div className="row">
                            <div className="col-6 text-left">
                              {this.previousButton}
                            </div>
                            <div className="col-6 text-right">
                              {this.nextButton}
							  <div className="submit_btn_class">
                              {this.submitButton}
							  </div>
                            </div>
                          </div>
                        </div>
                        </form>
                        <Step5
                          taxAmount={this.state.taxAmount}
                          currentStep={this.state.currentStep}
                          currencyText={this.state.currencyText}
                          handleChange={this.handleChange}
                        />

                        
                        {/* {this.state.success === "true" && (
                          <div className="d-flex align-items-center justify-content-end mt-3">
                            <span>You are Successfully submitted</span>
                            <Link to="/">
                              <button className="btn btn-primary mx-2">
                                Go to Home
                              </button>
                            </Link>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
           
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default withRouter(GetaQuote);
